import UserActionPage from './useractions/UserActions';
import UserLogPage from './logs/UserLogs';
import PdfsPage from './pdfs/Pdfs';
import ShareProfilePage from './shareprofiles/ShareProfiles'
import SearchProfilePage from './searchprofiles/SearchProfiles'
import ReportApp from './ReportApp';
import authRoles from '../../auth/authRoles';


const ReportAppConfig = {
  settings: {
    layout: {},
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'admin/report',
      element: <ReportApp />,
      children: [
        {
          path: '/admin/report/user/logs',
          element: <UserLogPage />,
        },
        {
          path: '/admin/report/pdf/logs',
          element: <PdfsPage />,
        },
        {
          path: '/admin/report/user/actions',
          element: <UserActionPage />,
        },
        {
          path: '/admin/report/share/profiles',
          element: <ShareProfilePage />,
        },
        {
          path: '/admin/report/search/profiles',
          element: <SearchProfilePage />,
        },
      ],
    },
  ],
};

export default ReportAppConfig;
