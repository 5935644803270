import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';

export const getSearchProfile = createAsyncThunk(
  'academyApp/searchprofiles/getSearchProfile',
  async () => {
      const response = await axios.post(`/api/profile/listof/searchregisteredprofile`);
      const data = await response.data;
      return data;
    
    }
);


export const selectSearchProfile = ({ academyApp }) => academyApp.searchprofiles;

const searchProfileSlice = createSlice({
  name: 'academyApp/searchprofiles',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getSearchProfile.pending]: (state, action) => null,
    [getSearchProfile.fulfilled]: (state, action) => action.payload
  },
});


export default searchProfileSlice.reducer;