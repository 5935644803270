import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getProfiles = createAsyncThunk('profilesApp/profiles/getProfiles', async (params) => {
  
  console.warn({params});

  const response = await axios.post('api/profile/admin/list',{params});
  const data = await response.data;
  return data;
});

export const removeProfiles = createAsyncThunk(
  'profilesApp/profiles/removeProfiles',
  async (profilesIds, { dispatch, getState }) => {
    console.error("Profiles Remove ids ");
    console.error({profilesIds});
    return false;
    await axios.delete('/api/ecommerce/orders', { data: orderIds });

    return orderIds;
  }
);

const profilesAdapter = createEntityAdapter({});

export const { selectAll: selectProfiles, selectById: selectProfileById } = profilesAdapter.getSelectors(
  (state) => state.profilesApp.profiles
);

const profilesSlice = createSlice({
  name: 'profilesApp/profiles',
  initialState: profilesAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setProfilesSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getProfiles.fulfilled]: profilesAdapter.setAll,
    [removeProfiles.fulfilled]: (state, action) => profilesAdapter.removeMany(state, action.payload),
  },
});

export const { setProfilesSearchText } = profilesSlice.actions;

export const selectProfilesSearchText = ({ profilesApp }) => profilesApp.profiles.searchText;

export default profilesSlice.reducer;
