import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import _ from '@lodash';
import jwtService from '../../auth/services/jwtService';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { showMessage } from 'app/store/fuse/messageSlice';
import { useDispatch } from 'react-redux';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  previous_password: yup
    .string()
    .required('Please Enter your previous password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  password: yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  passwordconfirmation: yup.string()
     .oneOf([yup.ref('password'), null], 'Passwords must match')
});

const defaultValues = {
  previous_password:'',
  password:'',
  passwordconfirmation:''
};

function Password(props) {
  
  const dispatch = useDispatch();

  const { control, formState, handleSubmit, reset ,setValue} = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;

  function onSubmit(formData) {

    delete formData.passwordconfirmation;

    jwtService
      .updateUserPassword(formData)
      .then((result) => {
          console.warn("password changed..");
          dispatch(showMessage({ message: "User password is changed successfully" }));
          setValue('password','');
          setValue('passwordconfirmation','');
      })
      .catch((_errors) => {
        console.log("saveError");
        console.log({_errors});
        return false;
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }
  // const { t } = useTranslation('examplePage');

  return (
    <Root
      header={
        <div className="p-24">
          <h3>Change Password</h3>
        </div>
      }
      content={
        <div className="p-24">
          
          <form
            name="userRegisterForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            
            <Controller
              name="previous_password"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="password-id">Previous Password <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="password"
                  error={!!errors.previous_password}
                  helperText={errors?.previous_password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="password-id">Password <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />

            <Controller
              name="passwordconfirmation"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="passwordconfirmation-id">Password Confirmation <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="password"
                  error={!!errors.passwordconfirmation}
                  helperText={errors?.passwordconfirmation?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />
           
     
            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-24"
              aria-label="Register"
              type="submit"
              size="large"
            >
              Update Password
            </Button>
          </form>

        </div>
      }
      scroll="content"
    />
  );
}

export default Password;