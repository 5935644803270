import { combineReducers } from '@reduxjs/toolkit';
import userlogs  from './userLogsSlice';
import pdfs  from './pdfsSlice';
import useractions  from './userActionsSlice';
import shareprofiles  from './shareProfilesSlice';
import searchprofiles  from './searchProfilesSlice';

const reducer = combineReducers({
  userlogs,
  pdfs,
  useractions,
  shareprofiles,
  searchprofiles
});

export default reducer;
