import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignUpConfig from '../main/sign-up/SignUpConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import Error404Page from '../main/404/Error404Page';
import ExampleConfig from '../main/example/ExampleConfig';
import AdminSignInConfig from '../main/admin-sign-in/AdminSignInConfig';
import AdminDashboardConfig from '../main/admin-dashboard/DashboardConfig';
import DataFieldConfig from '../main/admin-data-field/DataFieldConfig';
import UserConfig from '../main/admin-user/UserConfig';
import ProfileConfig from '../main/admin-profile/ProfileConfig';
import ProfileAppConfig from '../main/profile/ProfileAppConfig';
import ContactsAppConfig from '../main/contacts/ContactsAppConfig';
import DashboardConfig from '../main/dashboard/DashboardConfig';
import AcademyAppConfig from '../main/academy/AcademyAppConfig';
import ShareAppConfig from '../main/share/ContactsAppConfig';
import ReportAppConfig from '../main/admin-report/ReportAppConfig';
import ForgotConfig from '../main/forgot/ForgotConfig';
import ProfileDataConfig from '../main/profiledata/profileAppConfig';
import MasterConfig from '../main/admin-master/MasterConfig';
import PasswordConfig from '../main/change-password/PasswordConfig';
import UserPasswordConfig from '../main/change-password/UserPasswordConfig';

const routeConfigs = [ExampleConfig, SignOutConfig, SignInConfig, SignUpConfig, AdminSignInConfig, AdminDashboardConfig, DataFieldConfig, UserConfig, ProfileConfig, ProfileAppConfig, ContactsAppConfig, DashboardConfig, AcademyAppConfig, ShareAppConfig,ReportAppConfig,ForgotConfig,ProfileDataConfig,MasterConfig,PasswordConfig,UserPasswordConfig];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="/profiles" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: '/admin',
    element: <Navigate to="/admin/dashboad" />,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '*',
    element: <Navigate to="404" />,
  },
];

export default routes;