import i18next from 'i18next';

import PasswordPage from './Password';
import authRoles from '../../auth/authRoles';


const UserPasswordConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: 'change-password/user',
      element: <PasswordPage />,
    }
  ],
};

export default UserPasswordConfig;