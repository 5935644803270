import { combineReducers } from '@reduxjs/toolkit';
import masters from './mastersSlice';
import master from './masterSlice';

const reducer = combineReducers({
  masters,
  master
});

export default reducer;
