import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { lighten, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectProfiles } from '../../../store/profileSlice';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import clsx from 'clsx';


function LatestTableWidget() {
  const theme = useTheme();
  const [awaitRender, setAwaitRender] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const profilesdata = useSelector(selectProfiles);
  
  console.warn({profilesdata});
  
  return (
    <>
    <Paper className="flex flex-col flex-auto p-24 shadow rounded-2xl overflow-hidden">
      <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
        Latest Profiles
      </Typography>

      <div className="table-responsive">
        <Table className="w-full min-w-full">
          <TableHead>
            <TableRow>
                <TableCell key={0}>
                    <Typography
                        color="text.secondary"
                        className="font-semibold text-12 whitespace-nowrap"
                    >
                        Sno.
                    </Typography>
                </TableCell>
                <TableCell key={1}>
                    <Typography
                        color="text.secondary"
                        className="font-semibold text-12 whitespace-nowrap"
                    >
                        FileNumber
                    </Typography>
                </TableCell>
                <TableCell key={2}>
                    <Typography
                        color="text.secondary"
                        className="font-semibold text-12 whitespace-nowrap"
                    >
                        Created
                    </Typography>
                </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {profilesdata && profilesdata.latestprofiles.map((n,index) => {
                return <TableRow>
                    <TableCell key={index+2}>
                    <Typography
                        color="text.secondary"
                        className="font-semibold text-12 whitespace-nowrap"
                    >
                        {index+1}
                    </Typography>
                </TableCell>
                <TableCell key={index+3}>
                    <Button
                        className=""
                        component={Link}
                        to={"/profile/details/"+n.id}
                        variant="contained"
                      >
                        <Typography
                            className={clsx(
                              'inline-flex items-center font-bold text-10 px-10 py-2 rounded-full tracking-wide uppercase',
                              'bg-green-50 text-green-800 dark:bg-green-600 dark:text-green-50'
                            )}
                          >
                            {n.filenumber}
                          </Typography>
                      </Button>
                </TableCell>
                <TableCell key={index+4}>
                    <Typography
                        color="text.secondary"
                        className="font-semibold text-12 whitespace-nowrap"
                    >
                        {n.createdAt}
                    </Typography>
                </TableCell>
                </TableRow>
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
    <Paper className="flex flex-col flex-auto p-24 mt-20 shadow rounded-2xl overflow-hidden">
    <Typography className="text-lg font-medium tracking-tight leading-6 truncate">
      Latest Shared Profiles
    </Typography>

    <div className="table-responsive">
      <Table className="w-full min-w-full">
        <TableHead>
          <TableRow>
              <TableCell key={0}>
                  <Typography
                      color="text.secondary"
                      className="font-semibold text-12 whitespace-nowrap"
                  >
                      Sno.
                  </Typography>
              </TableCell>
              <TableCell key={1}>
                  <Typography
                      color="text.secondary"
                      className="font-semibold text-12 whitespace-nowrap"
                  >
                      Share By
                  </Typography>
              </TableCell>
              <TableCell key={1}>
                  <Typography
                      color="text.secondary"
                      className="font-semibold text-12 whitespace-nowrap"
                  >
                      Share With
                  </Typography>
              </TableCell>
              <TableCell key={1}>
                  <Typography
                      color="text.secondary"
                      className="font-semibold text-12 whitespace-nowrap"
                  >
                      Created
                  </Typography>
              </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
            {profilesdata && profilesdata.latestshareprofiles.map((n,index) => {
                return <TableRow>
                    <TableCell key={index+2}>
                    <Typography
                        color="text.secondary"
                        className="font-semibold text-12 whitespace-nowrap"
                    >
                        {index+1}
                    </Typography>
                </TableCell>
                <TableCell key={index+4}>
                    <Button
                        className=""
                        component={Link}
                        to={"/profile/details/"+n.anotherprofilefrom.id}
                        variant="contained"
                      >
                        <Typography
                            className={clsx(
                              'inline-flex items-center font-bold text-10 px-10 py-2 rounded-full tracking-wide uppercase',
                              'bg-green-50 text-green-800 dark:bg-green-600 dark:text-green-50'
                            )}
                          >
                            {n.anotherprofilefrom.filenumber}
                          </Typography>
                    </Button>
                </TableCell>
                <TableCell key={index+6}>
                <Button
                        className=""
                        component={Link}
                        to={"/profile/details/"+n.anotherprofile.id}
                        variant="contained"
                      >
                        <Typography
                            className={clsx(
                              'inline-flex items-center font-bold text-10 px-10 py-2 rounded-full tracking-wide uppercase',
                              'bg-green-50 text-green-800 dark:bg-green-600 dark:text-green-50'
                            )}
                          >
                            {n.anotherprofile.filenumber}
                          </Typography>
                    </Button>
                </TableCell>
                <TableCell key={index+2}>
                    <Typography
                        color="text.secondary"
                        className="font-semibold text-12 whitespace-nowrap"
                    >
                        {n.createdAt}
                    </Typography>
                </TableCell>
                </TableRow>
            })}
          </TableBody>
      </Table>
    </div>
  </Paper>
  </>
  );
}

export default memo(LatestTableWidget);
