import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import FusePageSimple from '@fuse/core/FusePageSimple';
import DemoContent from '@fuse/core/DemoContent';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as yup from 'yup';
import _ from '@lodash';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Route, Link, Routes, useParams, useNavigate} from 'react-router-dom';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { getMaster,updateMaster } from './store/masterSlice';
import { useDispatch, useSelector } from 'react-redux';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required('You must enter name'),
  type: yup.string().required('You must select type'),
  isActive: yup.string().required('You must select status'),
});


const defaultValues = {
  name: '',
  type:'',
  isActive:''
};

function  EditMasterPage(props) {
  
  const navigate = useNavigate();
  const [redirect, setRedirectValue] = useState(false);
  const {id} = useParams();
  const [records,setRecord] = useState(null);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if(redirect==true) {
      navigate('/admin/masters');
    }  
  }, [redirect]);

  const { control, formState, handleSubmit, reset, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;



  useEffect(() => {
    dispatch(getMaster(id)).then((res) =>setRecord(res.payload));
  }, [id]);

  useEffect(() => {
     if(records!=null) {
          setValue('name',records.name);
          setValue('type',records.type);
          setValue('isActive',records.isActive);
     }
  }, [records]);

  function onSubmit(formData) {
    formData.id = id;
    dispatch(updateMaster(formData)).then((res) => setRedirectValue(true));
  }

  return (
    <Root
      header={
        <div className="p-24">
          <h4>Edit Master</h4>
        </div>
      }
      content={
        <div className="p-24">
         
         <form
            name="profileRegisterForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
              <Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               
              <Grid item xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ width: '100%' }}>
                      <FormLabel id="name">Name <span className="text-red-600">*</span></FormLabel>
                      <TextField
                        {...field}
                        className="mb-24"
                        autoFocus
                        type="text"
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        variant="outlined"
                        required
                      />
                      </FormControl>
                    )}
                  />   
              </Grid>

          <Grid item xs={12}>
           <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="type-id">Type <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.type}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value={'1'}>Gotra</MenuItem>
                  <MenuItem value={'2'}>Qualification</MenuItem>
                
              </Select>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={12}>
        <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="datafield-type">Status <span className="text-red-600">*</span></FormLabel>
                <Select
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="name"
                  error={!!errors.isActive}
                  helperText={errors?.isActive?.message}
                  variant="outlined"
                  required
                >
                  <MenuItem value={'0'}>InActive</MenuItem>
                  <MenuItem value={'1'}>Active</MenuItem>
                </Select>
                </FormControl>
              )}
            />
        </Grid>  

        <Grid item xs={8}>
            
            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-24"
              aria-label="Register"
              type="submit"
              size="large"
            >
              Update
            </Button>
        </Grid>

      </Grid>
    </Box>   
          
          </form>

        </div>
      }
      scroll="content"
    />
  );
}

export default EditMasterPage;