import { combineReducers } from '@reduxjs/toolkit';
import projects from './projectsSlice';
import widgets from './widgetsSlice';
import profiles from './profileSlice';
import users from './userSlice';

const reducer = combineReducers({
  widgets,
  projects,
  profiles,
  users
});

export default reducer;
