import { combineReducers } from '@reduxjs/toolkit';
import states from './statesSlice';
import profile from './profileSlice';
import profiles from './profilesSlice';
import statecities from './citiesSlice';
import datafields from './datafieldsSlice';
import masters from './masterSlice';

const reducer = combineReducers({
  states,
  statecities,
  profile,
  profiles,
  datafields,
  masters
});

export default reducer;
