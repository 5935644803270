import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import FusePageSimple from '@fuse/core/FusePageSimple';
import _ from '@lodash';
import MasterHeader from './MasterHeader';
import MasterTable from './MasterTable';
import withReducer from 'app/store/withReducer';
import reducer from './store';



const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));


function MasterPage(props) {
  
  return (
    <Root
      header={<MasterHeader />}
      content={<MasterTable />}
      scroll="content"
    />
  );
}

export default withReducer('masterApp', reducer)(MasterPage);