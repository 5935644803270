import i18next from 'i18next';

import MasterPage from './Master';
import AddMasterPage from './AddMaster';
import EditMasterPage from './EditMaster';
import authRoles from '../../auth/authRoles';


const MasterConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'admin/masters',
      element: <MasterPage />,
    },
    {
      path: 'admin/add/master',
      element: <AddMasterPage />,
    },
    {
      path: 'admin/edit/master/:id',
      element: <EditMasterPage />,
    }
  ],
};

export default MasterConfig;