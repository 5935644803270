const jwtServiceConfig = {
  //signIn: 'api/auth/sign-in',
  signOut: 'api/user/logout',
  signIn: 'api/user/login',
  signUp: 'api/user/save',
  accessToken: 'api/user/access-token',
  updateUser: 'api/auth/user/update',
  saveDataField:'api/datafield/save',
  checkUserEmail:'api/user/checkemail',
  getUserList: 'api/user/list',
  getSingleUser: 'api/user/profile',
  updateUser: 'api/user/update',
  updateUserPassword: 'api/user/change-password',
  deleteUser: 'api/user/delete',
  getDataFieldList: 'api/datafield/list',
  getSingleDataField: 'api/datafield',
  updateDataField: 'api/datafield/update',
  deleteDataField: 'api/datafield/delete',
  saveProfile:'api/profile/save',
  getProfileList: 'api/profile/list',
  getSingleProfile: 'api/profile',
  updateProfile: 'api/profile/update',
  deleteProfile: 'api/profile/delete',
  getUserProfileList: 'api/profile/list/user',
  searchProfileList: 'api/profile/search',
  createProfileWithShare: 'api/profile/savewithshare',
  checkProfileType:'api/profile/checkprofile',
  getProfileListAdmin:'api/profile/admin/list',
  forgotpassword: 'api/user/forgot-password',
  deleteProfileImage: 'api/profile/deleteimage',
};

export default jwtServiceConfig;
