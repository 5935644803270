import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { lighten, ThemeProvider } from '@mui/material/styles';
import { selectMainThemeDark } from 'app/store/fuse/settingsSlice';
import { OutlinedInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useEffect } from 'react';
import { getShareProfiles, selectshareprofiles} from '../store/shareProfilesSlice';
import { styled } from '@mui/material/styles';
import ShareProfilesHeader from './ShareProfilesHeader';
import ShareProfilesTable from './ShareProfilesTable';
import FusePageSimple from '@fuse/core/FusePageSimple';


const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

function ShareProfiles() {
  
  const mainThemeDark = useSelector(selectMainThemeDark);
  const dispatch = useDispatch();
  const shareProfilesData = useSelector(selectshareprofiles);
  
  useEffect(() => {
    dispatch(getShareProfiles());
  }, [dispatch]);


  return (
    <Root
      header={<ShareProfilesHeader />}
      content={<ShareProfilesTable shareprofiles={shareProfilesData?.profilerecords}  users={shareProfilesData?.users} />}
      scroll="content"
    />
  );
}

export default ShareProfiles;