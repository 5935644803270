import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUsers = createAsyncThunk('userApp/users/getUsers', async () => {
  const response = await axios.get('/api/user/list');
  const data = await response.data;
  return data;
});


const usersAdapter = createEntityAdapter({});

export const { selectAll: selectUsers, selectById: selectUserById } = usersAdapter.getSelectors(
  (state) => state.userApp.users
);

const usersSlice = createSlice({
  name: 'userApp/users',
  initialState: usersAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setOrdersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: usersAdapter.setAll,
  },
});

export const { setOrdersSearchText } = usersSlice.actions;

export const selectOrdersSearchText = ({ userApp }) =>  userApp.users.searchText;

export default usersSlice.reducer;
