import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import FusePageSimple from '@fuse/core/FusePageSimple';
import DemoContent from '@fuse/core/DemoContent';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as yup from 'yup';
import _ from '@lodash';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import jwtService from '../../auth/services/jwtService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import jwtServiceConfig from '../../auth/services/jwtService/jwtServiceConfig';
import axios from 'axios';
import { useNavigate, Route, Link, Routes, useParams } from "react-router-dom";
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import { getState, selectState } from './store/statesSlice';
import { getStateCity, selectStateCity } from './store/citiesSlice';
import { getDataField, selectDataField} from './store/datafieldsSlice';
import { useDispatch, useSelector } from 'react-redux';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { getMaster, selectMaster} from './store/masterSlice';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import { element } from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));


/**
 * Form Validation Schema
 */


function EditProfilePage(props) {

  const [records, setRecord] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateData = useSelector(selectState);
  const cityData = useSelector(selectStateCity);
  const datafieldData = useSelector(selectDataField);
  const masterData = useSelector(selectMaster);
  const [redirect, setRedirectValue] = useState(false);
  const [emailfield, setEmailFieldValue] = useState(false);
  const [mobilefield,setMobileFieldValue] = useState(false);
  const [statelists, setStateList] = useState([]);
  const [citylists, setCityList] = useState([]);
  const [datafieldlists, setDataFieldList] = useState([]);
  const [saveotherimages, setOtherImages] = useState([]);
  const [masterlists, setMasterList] = useState([]);
  const [masterlists1, setMaster1List] = useState([]);
  const [bodydistext, setBodyDisText] = useState('No');   
  const [deleteprofileimageid, setProfileImageDelete] = useState(0);

  const heightValue = ['4.0',4.1,4.2,4.3,4.4,4.5,4.6,4.7,4.8,4.9,'4.10',4.11,'5.0',5.1,5.2,5.3,5.4,5.5,5.6,5.7,5.8,5.9,'5.10',5.11,'6.0',6.1,6.2,6.3,6.4,6.5,6.6,6.7,6.8,6.9,'6.10',6.11,'7.0',7.1,7.2,7.3,7.4,7.5,7.6,7.7,7.8,7.9,'7.10',7.11];

  const {id} = useParams();

  const [open, setOpen] = useState(false);

  const handleClickOpen = (deleteprofileid) => {
    setProfileImageDelete(deleteprofileid);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteProfileImageData = () => {
      deleteOther(deleteprofileimageid);
  }
  
 
  useEffect(() => {
    if(redirect==true) {
      navigate('/admin/profiles'); 
    }  
  }, [redirect]);

  useEffect(() => {
    dispatch(getState());
    dispatch(getDataField());
    dispatch(getMaster());
  }, [dispatch]);

  useEffect(() => {
    if(stateData!=null) {
      setStateList(stateData);
    }
    if(cityData!=null) {
      setCityList(cityData);
    }
    if(datafieldData!=null){
      setDataFieldList(datafieldData);
    }
    if(masterData!=null){
      const gotradata = masterData.filter((data)=> data.type==1);
      const qualificationdata = masterData.filter((data)=> data.type==2);
      setMasterList(gotradata);
      setMaster1List(qualificationdata);
    }
  }, [stateData,cityData,datafieldData,masterData]);

  
  const customFields = {};
  const customFieldSchemes = {};

  if(datafieldlists.length>0){
      for(let i=0;i<datafieldlists.length;i++){
          customFields[`data_field_${datafieldlists[i].id}`]='';
         if(datafieldlists[i].required==1){
            customFieldSchemes[`data_field_${datafieldlists[i].id}`]=yup.string().required(datafieldlists[i].name.charAt(0).toUpperCase() + datafieldlists[i].name.slice(1)+" is Required Field");
         }          
      }
  }

  const [defaultValues, setDefaultValue] = useState({
    name: '',
    dob: '',
    mobile:'',
    occupation:'',
    education:'',
    height:'',
    gotra:'',
    qualification:'',
    budget:'',
    budget_unit:'',
    manglik:'No',
    address:'',
    body_disable_text:'',
    state_id:'',
    city_id:'',
    reference:'',
    isActive:''
  });

  //console.warn({defaultValues});

  const schema = yup.object().shape({
    ...customFieldSchemes,
    first_name: yup.string().required('You must enter first name'),
    last_name: yup.string().required('You must enter last name'),
    email: yup.string().email('Invalid Email').required('You must enter email')
    .when((value,schema)=>{
       if(value!='' && emailfield) return schema.test('Unique Email','Email already in use', 
        function(value){return new Promise((resolve, reject) => {
            //setEmailFieldValue(false);
            axios.post(jwtServiceConfig.checkProfileType, {'value': value,'type':'email','id':id})
                  .then(res =>  {  if(res.count>0) { resolve(false)} resolve(true)})
        })});
    }),
    mobile: yup.string().required('you must enter the mobile number').matches(/^[0-9]+$/, "Must be only digits").min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits')
    .when((value,schema)=>{
      if(value!='' && mobilefield) return schema.test('Unique Mobile','Mobile already in use', 
       function(value){return new Promise((resolve, reject) => {
           //setMobileFieldValue(false);
           axios.post(jwtServiceConfig.checkProfileType, {'value': value,'type':'mobile','id':id})
                 .then(res => { if(res.count>0){resolve(false)} resolve(true)})
       })});
    }),
    father_name: yup.string().required('You must enter father name'),
    mother_name: yup.string().required('You must enter mother name'),
    dob: yup.string().required('You must enter date of birth'),
    dobtime: yup.string().required('You must enter time of birth'),
    occupation: yup.string().required('You must enter occupation'),
    education: yup.string().required('You must enter education'),
    height: yup.string().required('You must select height'),
    budget: yup.string().required('You must select budget'),
    budget_unit: yup.string().required('You must select budget unit'),
    manglik: yup.mixed().oneOf(['Yes','No','NA']).required('You must select manglik'),
    address: yup.string().required('You must enter address'),
    hobbies: yup.string().required('You must enter hobbies'),
    state_id: yup.string().required('You must select state'),
    city_id: yup.string().required('You must select city'),
    profile_image: yup.mixed().test("required", "You need to provide a image", (file) => {
          if (file && file.length > 0) return true;
          return false;
        }).test("type", "Valid image type are 'jpg', 'gif', 'png', 'jpeg'", (value) => {
          if (typeof value === "string") return true;
          return (
            value && value.length > 0 && (value[0].type === "image/jpeg" || value[0].type === "image/jpg" || value[0].type === "image/gif" || value[0].type === "image/png")
          );
        }).test("fileSize", "The file is too large", (value) => {
          if (typeof value === "string") return true;
          return value && value.length > 0 && value[0].size <= 1024 * 1024;
        })  
  });

  const { register,
    unregister,
    getValues,
    trigger,
    clearErrors,
    resetField, control, formState, handleSubmit, reset,setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;
  

  function getProfileData(id) {
    jwtService
      .getSingleProfileData(id)
      .then((result) => {
         dispatch(getStateCity(result.state_id));
         setValue('first_name',result.first_name);
         setValue('last_name',result.last_name);
         setValue('father_name',result.father_name);
         setValue('mother_name',result.mother_name);
         setValue('dob',result.dob);
         setValue('dobtime',result.dobtime);
         setValue('gender',result.gender);
         setValue('manglik',result.manglik);
         setValue('marriage_status',result.marriage_status);
         setValue('body_disable',result.body_disable);
         setValue('body_disable_text',result.body_disable_text);
         setValue('occupation',result.occupation);
         setValue('education',result.education);         
         setValue('mobile',result.mobile);
         setValue('height',result.height);
         setValue('budget',result.budget);
         setValue('budget_unit',result.budget_unit);
         setValue('state_id',result.state_id);
         setValue('address',result.address); 
         setValue('email',result.email);
         setValue('hobbies',result.hobbies);
         setValue('reference',result.reference);
         setValue('city_id',result.city_id);
         setValue('profile_image',result.profile_image);
         setValue('gotra',result.gotra);
         setValue('qualification',result.qualification);
         setValue('isActive',result.isActive);  
         
         setRecord(result);
         setOtherImages(result.profilesimage);

         const profiledatafields = result.profiledatafields;
        
         if(profiledatafields.length>0){
            for(let i=0;i<profiledatafields.length;i++){
                let fieldname = 'data_field_'+profiledatafields[i].datafield_id;
                setValue(fieldname,profiledatafields[i].datafield_value);
            }
         }
         
      })
      .catch((_errors) => {
        console.log("saveError");
        console.log({_errors});
        return false;
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }

  useEffect(() => {
    getProfileData(id);
    //dispatch(getOrders()).then(() => setLoading(false));
  }, [id]);


  function onSubmit(formData) {
      
    const data_fields = [];
    datafieldlists.map((element,index,array)=>{
      data_fields.push(element.id);
    });
    
    formData['data_fields']=data_fields;

    // Getting Image Record From Form Fields........
    
    if(typeof formData.profile_image=='string'){
      delete formData.profile_image;
    } else {
      formData.profile_image = formData.profile_image[0];   
    }

    /*
    if(typeof formData.other_image_1=='string'){
      delete formData.other_image_1;
    } else {
      formData.other_image_1 = formData.other_image_1[0];   
    }

    if(typeof formData.other_image_2=='string'){
      delete formData.other_image_2;
    } else {
      formData.other_image_2 = formData.other_image_2[0];   
    }

    if(typeof formData.other_image_3=='string'){
      delete formData.other_image_3;
    } else {
      formData.other_image_3 = formData.other_image_3[0];   
    }
    */
    var date = formData.dob;
    let stringdate = date.split("-");
    var date = new Date(stringdate[0]+'-'+stringdate[1]+'-'+stringdate[2])
    var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )) .toISOString() .split("T")[0];
    formData.dob = dateString;

    jwtService
      .updateProfile(formData,id)
      .then((result) => {
         navigate('/admin/profiles');
      })
      .catch((_errors) => {
        console.log("saveError");
        console.log({_errors});
        return false;
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }
  // const { t } = useTranslation('examplePage');

  const handleChange = (event) => {
    let state_id_val = event.target.value;
    setValue('state_id',state_id_val);
    if(state_id_val!='')
      dispatch(getStateCity(state_id_val));
    else
      setCityList([]);
};

  const handleBlur = (event) => {
      if(event.target.name=='email') {
          setEmailFieldValue(true);
      } else if(event.target.name=='mobile'){
          setMobileFieldValue(true);
      }    
  }

  const deleteOther = (imgId) => {
    
    const imgindex = saveotherimages.findIndex((element,index) => { return element.id==imgId; });
      if(imgindex>-1){
        saveotherimages.splice(imgindex,1);
      }
    
    setOtherImages([]);

    jwtService
      .deleteSingleProfileImage(imgId)
      .then((result) => {
          console.warn("Delete Single Profile Image >>");
          console.log({result});
          setOtherImages(saveotherimages);
          setOpen(false);
          setProfileImageDelete(0);
      })
      .catch((_errors) => {
        console.log("saveError");
        console.log({_errors});
        return false;
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });

     
      //setOtherImages(saveotherimages);
  }
  
  console.warn({saveotherimages});

  return (
    <Root
      header={
        <div className="p-24">
          <h3>Edit Profile : - {records.filenumber}</h3>
        </div>
      }
      content={
        <div className="p-24">
          <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="draggable-dialog-title"
                >
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Confirmation
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this image ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={deleteProfileImageData}>Delete</Button>
              </DialogActions>
            </Dialog>
          
          <form
            name="profileRegisterForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >


<Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               
              <Grid item xs={6}>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ width: '100%' }}>
                      <FormLabel id="first_name_id">First Name <span className="text-red-600">*</span></FormLabel>
                      <TextField
                        {...field}
                        className="mb-24"
                        autoFocus
                        type="text"
                        error={!!errors.first_name}
                        helperText={errors?.first_name?.message}
                        variant="outlined"
                        required
                      />
                      </FormControl>
                    )}
                  />   
              </Grid>

              <Grid item xs={6}>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ width: '100%' }}>
                      <FormLabel id="last_name_id">Last Name <span className="text-red-600">*</span></FormLabel>
                      <TextField
                        {...field}
                        className="mb-24"
                        autoFocus
                        type="text"
                        error={!!errors.last_name}
                        helperText={errors?.last_name?.message}
                        variant="outlined"
                        required
                      />
                      </FormControl>
                    )}
                  />   
              </Grid>

        <Grid item xs={6}>
          <Controller
              name="father_name"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="father_name_id">Father Name <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.father_name}
                  helperText={errors?.father_name?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />    
        </Grid>
        <Grid item xs={6}>

           <Controller
              name="mother_name"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="mother_name_id">Mother Name <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.mother_name}
                  helperText={errors?.mother_name?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
             <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="email-id">Email <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  onBlur={handleBlur}
                />
                </FormControl>
              )}
            />   
        </Grid>
        
        <Grid item xs={6}>
              <Controller
              name="mobile"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="mobile-id">Mobile <span className="text-red-600">*</span></FormLabel>
                 <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="number"
                  error={!!errors.mobile}
                  helperText={errors?.mobile?.message}
                  variant="outlined"
                  required
                  fullWidth
                  onBlur={handleBlur}
                />
                </FormControl>
              )}
            />                  
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="dob"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="dob-id">Date of Birth <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="date"
                  error={!!errors.dob}
                  helperText={errors?.dob?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />                   
        </Grid>
        <Grid item xs={6}>
           <Controller
              name="dobtime"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="dobtime-id">Time of Birth <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="time"
                  error={!!errors.dobtime}
                  helperText={errors?.dobtime?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />                   
        </Grid>
        
        <Grid item xs={6}>
           <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="gender-id">Gender <span className="text-red-600">*</span></FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'Male'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'Male'} control={<Radio />} label="Male" />
                  <FormControlLabel value={'Female'} control={<Radio />} label="Female" />
                </RadioGroup>
                </FormControl>
              )}
            /> 
        </Grid>


        
        <Grid item xs={6}>
            <Controller
              name="manglik"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="manglik-id">Manglik <span className="text-red-600">*</span></FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'NA'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'No'} control={<Radio />} label="No" />
                  <FormControlLabel value={'NA'} control={<Radio />} label="NA" />
                </RadioGroup>
                </FormControl>
              )}
            />     
        </Grid>

        <Grid item xs={6}>
            <Controller
              name="marriage_status"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="marriage_status_id">Marriage Status <span className="text-red-600">*</span></FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'single'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'single'} control={<Radio />} label="Single" />
                  <FormControlLabel value={'married'} control={<Radio />} label="Married" />
                  <FormControlLabel value={'widowed'} control={<Radio />} label="Widowed" />
                  <FormControlLabel value={'divorced'} control={<Radio />} label="Divorced" />
                </RadioGroup>
                </FormControl>
              )}
            />     
        </Grid>

        <Grid item xs={6}>
            <Controller
              name="body_disable"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="body_disable_id">Body Disable <span className="text-red-600">*</span></FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'No'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'No'} control={<Radio />} label="No" />
                </RadioGroup>
                </FormControl>
              )}
            />     
        </Grid>
        
        <Grid item xs={6}>
            <Controller
              name="body_disable_text"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="body_disable_text_id">Body Disable Part</FormLabel>
                 <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.body_disable_text}
                  helperText={errors?.body_disable_text?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />     
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="occupation"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="occupation-id">Occupation <span className="text-red-600">*</span></FormLabel>
                 <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.occupation}
                  helperText={errors?.occupation?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            /> 
        </Grid>
        <Grid item xs={6}>
            <Controller
              name="education"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="education-id">Education <span className="text-red-600">*</span></FormLabel>
                 <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.education}
                  helperText={errors?.education?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />                  
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="height"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="height-id">Height <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.height}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select Height</em></MenuItem>
                {heightValue.map((n,index)=>{ 
                  return <MenuItem key={index} value={n}>{n}</MenuItem>;
                })}
                </Select>
                <FormHelperText error={!!errors.height}>{errors?.height?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>
        
        <Grid item xs={6}>
           <Controller
              name="budget"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '60%' }}>
                <FormLabel id="budget-id">Budget  <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.budget}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select Budget</em></MenuItem>
                {[...Array(100)].map((e, i) => {
                  return <MenuItem key={i} value={i+1}>{i+1}</MenuItem>;
                })}
                </Select>
                <FormHelperText error={!!errors.budget}>{errors?.budget?.message}</FormHelperText>
                </FormControl>
              )}
            /> &nbsp;
            <Controller
              name="budget_unit"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '30%' }}>
                <FormLabel id="budget_unit_id">Budget Unit  <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.budget_unit}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select Budget Unit</em></MenuItem>
                <MenuItem key={'Cr'} value={'Cr'}>Cr</MenuItem>
                <MenuItem key={'Lakh'} value={'Lakh'}>Lakh</MenuItem>
                </Select>
                <FormHelperText error={!!errors.budget_unit}>{errors?.budget_unit?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
            <Controller
              name="hobbies"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="hobbies-id">Hobbies <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.hobbies}
                  helperText={errors?.hobbies?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="gotra"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="gotra-id">Gotra <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.gotra}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select Gotra</em></MenuItem>
                
                {masterlists.map((n,index)=>{ 
                  return <MenuItem key={index} value={n.name}>{n.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={!!errors.gotra}>{errors?.gotra?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="qualification"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="qualification-id">Qualification <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.height}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select Qualification</em></MenuItem>
                {masterlists1.map((n,index)=>{ 
                  return <MenuItem key={index} value={n.name}>{n.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={!!errors.qualification}>{errors?.qualification?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="state_id"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="state-id">State <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.state_id}
                 variant="outlined"
                 required
                 fullWidth
                 onChange={handleChange}
              >
                <MenuItem value=''><em>Select State</em></MenuItem>
                
                {statelists.map((n,index)=>{ 
                  return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={!!errors.state_id}>{errors?.state_id?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="city_id"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="city-id">City <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.height}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select City</em></MenuItem>
                {citylists.map((n,index)=>{ 
                  return <MenuItem key={index} value={n.id}>{n.city}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={!!errors.city_id}>{errors?.city_id?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="address-id">Address <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  id="address"
                  label="Address"
                  type="text"
                  multiline
                  rows={5}
                  variant="outlined"
                  fullWidth
                  error={!!errors.address}
                  helperText={errors?.address?.message}
                />
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
            <Controller
              name="reference"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="reference-id">Reference</FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.reference}
                  helperText={errors?.reference?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={5}>
        <FormControl sx={{ width: '100%' }}>
                <FormLabel id="profile_image-id">Profile Image  <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...register("profile_image")}
                  className="mb-24"
                  autoFocus
                  type="file"
                  error={!!errors.profile_image}
                  helperText={errors?.profile_image?.message}
                  variant="outlined"
                  required
                  fullWidth
                  accept="image/jpeg,image/gif,image/png"
                />
                </FormControl>                    
        </Grid>
        <Grid item xs={1}>
              {records.profile_image != null &&
              records.profile_image != "" ? (
                <img
                          className="w-full h-full block rounded"
                          src={records.profile_image}
                          alt={records.filenumber}
                        />
              ) : (
                ""
              )}
        </Grid>

        <Grid item xs={6}>
        <FormControl sx={{ width: '100%' }}>
                <FormLabel id="other_image-id">Other Image </FormLabel>
                <TextField
                  {...register("other_image")}
                  className="mb-24"
                  autoFocus
                  type="file"
                  error={!!errors.other_image}
                  helperText={errors?.other_image?.message}
                  variant="outlined"
                  required
                  fullWidth
                  inputProps={{
                    multiple: true
                  }}
                  accept="image/jpeg,image/gif,image/png"
                />
                </FormControl>                    
        </Grid>
        
        
        <Grid item xs={6}>
           <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="isActive-id">Status <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.isActive}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value={'0'}>InActive</MenuItem>
                <MenuItem value={'1'}>Active</MenuItem>
                </Select>
                <FormHelperText error={!!errors.isActive}>{errors?.isActive?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        {
                datafieldlists && datafieldlists.map((element,index,array)=>{
                  let dataFieldValues = element.default_value!=null?element.default_value.split(","):[];
                   return <Grid item xs={6}>
                              <Controller
                                name={"data_field_"+element.id}
                                control={control}
                                render={({ field }) => (
                                  <FormControl sx={{ width: '100%' }}>
                                  <FormLabel id={element.name.toLowerCase()+"_id"}>{element.name.charAt(0).toUpperCase() + element.name.slice(1)} {element.required==1?<span className="text-red-600">*</span>:''}</FormLabel>

                                  {element.type==1?<TextField
                                    {...field}
                                    className="mb-24"
                                    autoFocus
                                    type="text"
                                    error={!!errors['data_field_'+element.id]}
                                    helperText={errors?.['data_field_'+element.id]?.message}
                                    variant="outlined"
                                    required
                                  />:''}
                                  {element.type==2?<TextField
                                    {...field}
                                    className="mb-24"
                                    autoFocus
                                    type="number"
                                    error={!!errors['data_field_'+element.id]}
                                    helperText={errors?.['data_field_'+element.id]?.message}
                                    variant="outlined"
                                    required
                                  />:''}
                                  {element.type==3?<TextField
                                    {...field}
                                    className="mb-24"
                                    autoFocus
                                    type="text"
                                    error={!!errors['data_field_'+element.id]}
                                    helperText={errors?.['data_field_'+element.id]?.message}
                                    variant="outlined"
                                    required
                                  />:''}
                                  {element.type==9?<TextField
                                    {...field}
                                    className="mb-24"
                                    autoFocus
                                    type="color"
                                    error={!!errors['data_field_'+element.id]}
                                    helperText={errors?.['data_field_'+element.id]?.message}
                                    variant="outlined"
                                    required
                                  />:''}
                                  { element.type==4?<><Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        error={!!errors['data_field_'+element.id]}
                                        variant="outlined"
                                        required
                                        fullWidth
                                      >
                                        <MenuItem value=''><em>Select {element.name.charAt(0).toUpperCase() + element.name.slice(1)}</em></MenuItem>
                                        {dataFieldValues.map((n,index)=>{ 
                                          return <MenuItem key={index+'_'+n} value={n}>{n}</MenuItem>;
                                        })}
                                        </Select>
                                    <FormHelperText error={!!errors['data_field_'+element.id]}>{errors?.['data_field_'+element.id]?.message}</FormHelperText></>:''}
                                    {element.type==5?<><Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        error={!!errors['data_field_'+element.id]}
                                        variant="outlined"
                                        required
                                        fullWidth
                                      >
                                        <MenuItem value=''><em>Select {element.name.charAt(0).toUpperCase() + element.name.slice(1)}</em></MenuItem>
                                        {dataFieldValues.map((n,index)=>{ 
                                          return <MenuItem key={index+'_'+n} value={n}>{n}</MenuItem>;
                                        })}
                                        </Select>
                                    <FormHelperText error={!!errors['data_field_'+element.id]}>{errors?.['data_field_'+element.id]?.message}</FormHelperText></>:''}
                                    {element.type==6?<><RadioGroup
                                                {...field}
                                                row
                                                aria-labelledby="demo-radio-buttons-group-label"
                                               
                                                name="radio-buttons-group"
                                              >{dataFieldValues.map((n,index)=>{ 
                                          return <FormControlLabel value={n.charAt(0).toUpperCase() + n.slice(1)} control={<Radio />} label={n.charAt(0).toUpperCase() + n.slice(1)} />;                                      ;
                                        })}</RadioGroup><FormHelperText error={!!errors['data_field_'+element.id]}>{errors?.['data_field_'+element.id]?.message}</FormHelperText></>:''}
                                    {element.type==7?<><TextField 
                                                        {...field}
                                                        className="mb-24"
                                                        autoFocus
                                                        type="date"
                                                        error={!!errors['data_field_'+element.id]}
                                                        helperText={errors?.['data_field_'+element.id]?.message}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                      /></>:''}
                                    {element.type==8?<><TextField 
                                                        {...field}
                                                        className="mb-24"
                                                        autoFocus
                                                        type="time"
                                                        error={!!errors['data_field_'+element.id]}
                                                        helperText={errors?.['data_field_'+element.id]?.message}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                      /></>:''}

                                  </FormControl>
                                )}
                                
                              />   
                          </Grid>;
                })
              }

              
        <Grid item xs={12} md={6}>
          
        { saveotherimages && saveotherimages.length>0 
                ?<Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
            Saved Other Images
          </Typography>:''}
          {
                saveotherimages && saveotherimages.map((element,index,array)=>{
                   return <List>
                   <ListItem
                   >
                     <ListItemAvatar>
                       <Avatar sx={{ width: 60, height: 60 }}>
                       <img
                          className="w-full h-full block rounded"
                          src={element.image_name}
                          alt={element.image_name}
                        />
                       </Avatar>
                     </ListItemAvatar>
                     &nbsp;
                     <ListItemText> 
                      <IconButton onClick={e=>handleClickOpen(element.id)} edge="end" aria-label="delete">
                          <DeleteIcon />
                        </IconButton>
                    </ListItemText>
                   </ListItem>
               </List>;
                })
              }
            
        </Grid>      

        <Grid item xs={8}>
            
            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-24"
              aria-label="Register"
              type="submit"
              size="large"
            >
              Update
            </Button>
        </Grid>

      </Grid>
    </Box>   
          

          </form>

        </div>
      }
      scroll="content"
    />
  );
}

export default EditProfilePage;