import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { lighten, ThemeProvider } from '@mui/material/styles';
import { selectMainThemeDark } from 'app/store/fuse/settingsSlice';
import { OutlinedInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useEffect } from 'react';
import { getUsersLog, selectusersLog} from '../store/userLogsSlice';
import { styled } from '@mui/material/styles';
import UserLogsHeader from './UserLogsHeader';
import UserLogsTable from './UserLogsTable';
import FusePageSimple from '@fuse/core/FusePageSimple';


const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

function UserLogs() {
  
  const mainThemeDark = useSelector(selectMainThemeDark);
  const dispatch = useDispatch();
  const usersLogData = useSelector(selectusersLog);
  
  useEffect(() => {
    dispatch(getUsersLog({userid:0}));
  }, [dispatch]);

  return (
    <Root
      header={<UserLogsHeader logsuser={usersLogData?.userlogs.users} getuserlogfnc={getUsersLog} />}
      content={<UserLogsTable userlogs={usersLogData?.userlogs.logs}  logsuser={usersLogData?.userlogs.users} />}
      scroll="content"
    />
  );
}

export default UserLogs;