import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);


let navigationConfig = [
  {
    id: 'dashboad-component',
    title: 'Dashboard',
    translate: 'Dashboard',
    type: 'item',
    icon: 'heroicons-outline:home',
    url: 'dashboad',
    role:['user']
  },
  {
    id: 'profile-component',
    title: 'Profiles',
    translate: 'Profiles',
    type: 'item',
    icon: 'heroicons-outline:clipboard-check',
    url: 'profiles',
    role:['user']
  },
  {
    id: 'admin-dashboad-component',
    title: 'Dashboard',
    translate: 'Dashboard',
    type: 'item',
    icon: 'heroicons-outline:home',
    url: 'admin/dashboad',
    role:['superadmin','admin']
  },
  {
    id: 'data-field-component',
    title: 'DataField',
    translate: 'DataField',
    type: 'item',
    icon: 'heroicons-outline:chart-pie',
    url: 'admin/data-field',
    role:['superadmin','admin']
  },
  {
    id: 'user-field-component',
    title: 'Users',
    translate: 'Users',
    type: 'item',
    icon: 'heroicons-outline:user-group',
    url: 'admin/users',
    role:['superadmin','admin']
  },
  {
    id: 'admin-profile-component',
    title: 'Profiles',
    translate: 'Profiles',
    type: 'item',
    icon: 'heroicons-outline:chart-pie',
    url: 'admin/profiles',
    role:['superadmin','admin','operator']
  },
  {
    id: 'admin-unregistered-profile-component',
    title: 'Unregistered Profiles',
    translate: 'Unregistered-Profiles',
    type: 'item',
    icon: 'heroicons-outline:chart-pie',
    url: 'admin/unregistered-profile',
    role:['superadmin','admin','operator']
  },
  {
    id: 'admin-master-component',
    title: 'Master',
    translate: 'Masters',
    type: 'item',
    icon: 'heroicons-outline:shopping-cart',
    url: 'admin/masters',
    role:['superadmin','admin']
  },
  {
        id: 'admin-report-componenet',
        title: 'Reports',
        type: 'collapse',
        icon: 'heroicons-outline:support',
        url: '#',
        role:['superadmin','admin'],
        children: [
          {
            id: 'admin-report.user',
            title: 'User Logs',
            type: 'item',
            icon: 'heroicons-outline:academic-cap',
            url: 'admin/report/user/logs',
            role:['superadmin','admin'],
            end: true,
          },
          {
            id: 'admin-report.pdf',
            title: 'Pdf Download',
            type: 'item',
            role:['superadmin','admin'],
            icon: 'heroicons-outline:shopping-cart',
            url: 'admin/report/pdf/logs',
          },
          {
            id: 'admin-report.action',
            title: 'User Actions',
            type: 'item',
            icon: 'heroicons-outline:cloud',
            url: 'admin/report/user/actions',
            role:['superadmin','admin']
          },
          {
            id: 'admin-report.shareprofile',
            title: 'Share Profile',
            type: 'item',
            icon: 'heroicons-outline:support',
            url: 'admin/report/share/profiles',
            role:['superadmin','admin']
          },
          {
            id: 'admin-report.searchprofile',
            title: 'Search Profile',
            type: 'item',
            icon: 'heroicons-outline:view-boards',
            url: '/admin/report/search/profiles',
            role:['superadmin','admin']
          },
        ],
      },
];

export default navigationConfig;