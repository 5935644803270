import i18next from 'i18next';

import UserPage from './User';
import AddUserPage from './AddUser';
import EditUserPage from './EditUser';
import authRoles from '../../auth/authRoles';


const UserConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'admin/users',
      element: <UserPage />,
    },
    {
      path: 'admin/add/user',
      element: <AddUserPage />,
    },
    {
      path: 'admin/edit/user/:id',
      element: <EditUserPage />,
    }
  ],
};

export default UserConfig;