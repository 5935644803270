import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getMasters = createAsyncThunk('masterApp/masters/getMasters', async () => {
  const response = await axios.get('/api/masters/list');
  const data = await response.data;
  console.warn({data});
  return data;
});



const mastersAdapter = createEntityAdapter({});

export const { selectAll: selectMasters, selectById: selectMasterById } = mastersAdapter.getSelectors(
  (state) => state.masterApp.masters
);

const mastersSlice = createSlice({
  name: 'masterApp/masters',
  initialState: mastersAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setMastersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getMasters.fulfilled]: mastersAdapter.setAll,
  },
});

export const { setMastersSearchText } = mastersSlice.actions;

export const selectMastersSearchText = ({ masterApp }) => masterApp.masters.searchText;

export default mastersSlice.reducer;