/**
 * Authorization Roles
 */
/* 
const authRoles = {
  admin: ['admin'],
  staff: ['admin', 'staff'],
  user: ['admin', 'staff', 'user'],
  onlyGuest: [],
};  */

const authRoles = {
  superadmin: ['superadmin'],
  admin: ['superadmin','admin'],
  operator: ['superadmin','admin','operator'],
  user: ['user'],
  onlyGuest: [],
};

export default authRoles;
