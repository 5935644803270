import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUsers = createAsyncThunk(
  'projectDashboardApp/users/getUsers',
  async () => {
    console.warn("Called get >>>>");
    const response = await axios.post('/api/user/user-dashboard');
    console.warn("projectDashboardApp/users");
    console.warn({response});
    return response.data;
  }
);


const userSlice = createSlice({
  name: 'projectDashboardApp/users',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => action.payload,
  },
});

export const selectUsers = ({ projectDashboardApp }) => projectDashboardApp.users;

export default userSlice.reducer;