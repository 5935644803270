import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import FusePageSimple from '@fuse/core/FusePageSimple';
import DemoContent from '@fuse/core/DemoContent';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as yup from 'yup';
import _ from '@lodash';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import jwtService from '../../auth/services/jwtService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Route, Link, Routes, useParams, useNavigate} from 'react-router-dom';
import { useEffect, useState } from 'react';


const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required('You must enter user name'),
  mobile: yup.string().min(10).max(10).required('You must enter mobile number'),
  role: yup.mixed().oneOf(['0','2','3']).required('You must select user role'),
  gender: yup.mixed().oneOf(['Male','Female']).required('You must select user gender'),
  /*password: yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  passwordconfirmation: yup.string()
     .oneOf([yup.ref('password'), null], 'Passwords must match')*/
});

const defaultValues = {
  name: '',
  email: '',
  mobile: '',
  role: '',
  gender:'',
  isActive:''
};

function  EditUserPage(props) {
  
  const navigate = useNavigate();
  const {id} = useParams();
  const [records,setRecord] = useState();

  const { control, formState, handleSubmit, reset, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;


    console.log({id});

  function getUserData(id) {
    jwtService
      .getSingleUserData(id)
      .then((result) => {
         console.log("Edit User");
         console.log({result});
         let gender = result.gender=='M'?'Male':'Female';
         setValue('name',result.name);
         setValue('email',result.email);
         setValue('gender',gender);
         setValue('mobile',result.mobile);
         setValue('role',result.role);
         setValue('isActive',result.isActive);
         //setRecord(result);
      })
      .catch((_errors) => {
        console.log("saveError");
        console.log({_errors});
        return false;
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }

  useEffect(() => {
    getUserData(id);
    //dispatch(getOrders()).then(() => setLoading(false));
  }, [id]);


  function onSubmit(formData) {

    /*console.log({formData});
    return false;*/
    formData.id = id;

    jwtService
      .updateUser(formData)
      .then((result) => {
         navigate('/admin/users');
      })
      .catch((_errors) => {
        console.log("saveError");
        console.log({_errors});
        return false;
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }
  // const { t } = useTranslation('examplePage');
  console.log({dirtyFields});
  console.log({isValid});

  return (
    <Root
      header={
        <div className="p-24">
          <h4>Edit User</h4>
        </div>
      }
      content={
        <div className="p-24">
         
          <form
            name="userRegisterForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >

            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="name-id">User Name <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="email-id">User Email <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  disabled
                  fullWidth
                />
                </FormControl>
              )}
            />


          
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="role-id">Role <span className="text-red-600">*</span></FormLabel>
                <Select
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="select"
                  error={!!errors.role}
                  helperText={errors?.role?.message}
                  variant="outlined"
                  required
                  fullWidth
                >
                  <MenuItem value={'0'}>User</MenuItem>
                  <MenuItem value={'2'}>Admin</MenuItem>
                  <MenuItem value={'3'}>Operator</MenuItem>
                </Select>
                </FormControl>
              )}
            />

            <Controller
              name="mobile"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="mobile-id">Mobile <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  type="number"
                  error={!!errors.mobile}
                  helperText={errors?.mobile?.message}
                  variant="outlined"
                />
                </FormControl>
              )}
            />

            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="gender-id">Gender <span className="text-red-600">*</span></FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'Male'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'Male'} control={<Radio />} label="Male" />
                  <FormControlLabel value={'Female'} control={<Radio />} label="Female" />
                </RadioGroup>
                </FormControl>
              )}
            />

            <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="datafield-type">Status <span className="text-red-600">*</span></FormLabel>
                <Select
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="name"
                  error={!!errors.isActive}
                  helperText={errors?.isActive?.message}
                  variant="outlined"
                  required
                  fullWidth
                >
                  <MenuItem value={'0'}>InActive</MenuItem>
                  <MenuItem value={'1'}>Active</MenuItem>
                </Select>
                </FormControl>
              )}
            />

     
            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-24"
              aria-label="Register"
              type="submit"
              size="large"
            >
              Update
            </Button>
          </form>

        </div>
      }
      scroll="content"
    />
  );
}

export default EditUserPage;