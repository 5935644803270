import { lazy } from 'react';
import authRoles from '../../auth/authRoles';

const ProfileApp = lazy(() => import('./ProfileApp'));

const profileAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.superadmin,
  routes: [
    {
      path: 'admin/view/profile/:id',
      element: <ProfileApp />,
    },
  ],
};

export default profileAppConfig;
