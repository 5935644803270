import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  checkRoleName = (value) => {
    const roleArray = ["user","superadmin","admin","operator"];
    return roleArray[value];
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (err) => {
        
        return new Promise((resolve, reject) => {
          /*console.error("SetInterceptors Errors");
          console.error({err});*/

          if (err.response.data.logout === true) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Token expired');
            this.setSession(null);
          }
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid access_token');
            this.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };


  createProfileWithShare = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.createProfileWithShare, data).then((response) => {
        if (response.success) {
          resolve(response.message);
        } else {
          reject(response.error);
        }
      });
    });
  };


  searchProfileList = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.searchProfileList,data).then((response) => {
        if (response.success) {
          resolve(response.profile);
        } else {
          reject(response.error);
        }
      });
    });
  };

   getUserData = () => {
    return new Promise((resolve, reject) => {
      axios.get(jwtServiceConfig.getUserList).then((response) => {
        
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };

  deleteSingleDataField = (id) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.deleteDataField,{dataFieldId:id}).then((response) => {
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };

  deleteSingleProfileImage = (imgid) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.deleteProfileImage,{profileId:imgid}).then((response) => {
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };

  deleteSingleProfile = (id) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.deleteProfile,{profileId:id}).then((response) => {
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };

  deleteSingleUser = (userid) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.deleteUser,{id:userid}).then((response) => {
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };

  getDataFieldData = () => {
    return new Promise((resolve, reject) => {
      axios.get(jwtServiceConfig.getDataFieldList).then((response) => {
        
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };


  getProfileData = () => {
    return new Promise((resolve, reject) => {
      axios.get(jwtServiceConfig.getProfileList).then((response) => {
        
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };

  getProfileDataAdmin = (only_share=0) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.getProfileListAdmin,{only_share}).then((response) => {
        
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };

  getUserProfileData = () => {
    return new Promise((resolve, reject) => {
      axios.get(jwtServiceConfig.getUserProfileList).then((response) => {
        
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };

  getSingleUserData = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(jwtServiceConfig.getSingleUser+'/'+id).then((response) => {
        
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };


  getSingleFieldData = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(jwtServiceConfig.getSingleDataField+'/'+id).then((response) => {
        
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };


  getSingleProfileData = (id) => {
    return new Promise((resolve, reject) => {
      axios.get(jwtServiceConfig.getSingleProfile+'/'+id).then((response) => {
        
        if (response.success) {
          resolve(response.data);
        } else {
          reject(response.error);
        }
      });
    });
  };

  updateDataField = (data,id) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.updateDataField+"/"+id, data).then((response) => {
        if (response.success) {
          resolve(response.message);
        } else {
          reject(response.error);
        }
      });
    });
  };


  updateProfile = (data,id) => {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      axios.post(jwtServiceConfig.updateProfile+"/"+id, data,config).then((response) => {
        if (response.success) {
          resolve(response.message);
        } else {
          reject(response.error);
        }
      });
    });
  };


  createDataField = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.saveDataField, data).then((response) => {
        if (response.success) {
          resolve(response.message);
        } else {
          reject(response.error);
        }
      });
    });
  };


  createProfile = (data) => {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      axios.post(jwtServiceConfig.saveProfile, data, config).then((response) => {
        if (response.success) {
          resolve(response.message);
        } else {
          reject(response.error);
        }
      });
    });
  };


  createUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.signUp, data).then((response) => {
        if (response.success) {
          /*this.setSession(response.data.access_token);*/
          resolve(response.message);
          //this.emit('onLogin', response.data.user);
        } else {
          reject(response.message);
        }
      });
    });
  };

  updateUser = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.updateUser, data).then((response) => {
        if (response.success) {
          /*this.setSession(response.data.access_token);*/
          resolve(response.message);
          //this.emit('onLogin', response.data.user);
        } else {
          reject(response.message);
        }
      });
    });
  };

  updateUserPassword = (data) => {
    return new Promise((resolve, reject) => {
      axios.post(jwtServiceConfig.updateUserPassword, data).then((response) => {
        if (response.success) {
          /*this.setSession(response.data.access_token);*/
          resolve(response.message);
          //this.emit('onLogin', response.data.user);
        } else {
          reject(response.message);
        }
      });
    });
  };

  ForgotPassword = (email) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.forgotpassword, {
            email })
        .then((response) => {
          
          if (response.data.message) {
            
            resolve(response.data.message);
            
          } else {
            reject(response.data.error);
          }
        }).catch((error) => {
          reject(error.response.data);
        });
    });
  }

  signInWithEmailAndPassword = (email, password, type) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, {
            email,
            password,
            type
        })
        .then((response) => {
          
          if (response.data.user) {
            
           response.data.user.role = this.checkRoleName(response.data.user.usertype);
           response.data.user.photoURL = 'assets/images/avatars/brian-hughes.jpg';
           response.data.user.shortcuts = ['apps.calendar', 'apps.mailbox', 'apps.contacts', 'apps.tasks'];
           if(response.data.user.role=="operator")
            response.data.user.loginRedirectUrl = '/admin/profiles';
           else if(response.data.user.role!="user")
            response.data.user.loginRedirectUrl = '/admin';
           else
            response.data.user.loginRedirectUrl = '/dashboad';
            
            console.warn(response.data);
            this.setSession(response.data.token);
            this.emit('onLogin', response.data.user);
            resolve(response.data.user);
            
          } else {
            reject(response.data.error);
          }
        }).catch((error) => {
          console.error("signInWithEmail Error Catch >>>");
          console.error({error});
          reject(error.response.data);
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.accessToken, {
            access_token: this.getAccessToken()
        })
        .then((response) => {

          if (response.data.user) {
           response.data.user.role = this.checkRoleName(response.data.user.usertype);
           response.data.user.photoURL = 'assets/images/avatars/brian-hughes.jpg';
           response.data.user.shortcuts = ['apps.calendar', 'apps.mailbox', 'apps.contacts', 'apps.tasks'];
           if(response.data.user.role!="user")
            response.data.user.loginRedirectUrl = '/admin';
            this.setSession(response.data.token);
            resolve(response.data.user);
            this.emit('onLogin', response.data.user);
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
          
        })
        .catch((error) => {
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  updateUserData = (user) => {
    return axios.post(jwtServiceConfig.updateUser, {
      user,
    });
  };

  setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => { 
          this.setSession(null);
          this.emit('onLogout', 'Logged out');
        };

 
  logoutFromAdmin = () => {
     this.setSession(null);
     this.emit('onLogout', 'Logged out');
     return true;
      axios
        .post(jwtServiceConfig.signOut)
        .then((response) => {
          console.error("Logout Axios Error >>");
          console.error({response});
          this.setSession(null);
          this.emit('onLogout', 'Logged out');
        })
        .catch((error) => {
          
          this.setSession(null);
          this.emit('onLogout', 'Logged out');
        });
  };
  

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new JwtService();

export default instance;
