import { createAsyncThunk,  createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getSearchProfiles = createAsyncThunk('reportApp/searchprofiles/get', async () => {
  const response = await axios.post('/api/report/searchprofilelist');
  const data = await response.data;
  return data;
});


const searchProfilesSlice = createSlice({
  name: 'reportApp/searchprofiles',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getSearchProfiles.fulfilled]: (state, action) => action.payload,
  },
});


export const selectsearchprofiles = ({ reportApp }) =>  reportApp.searchprofiles;

export default searchProfilesSlice.reducer;