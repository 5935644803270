import { createAsyncThunk,  createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUsersLog = createAsyncThunk('reportApp/userlogs/get', async (state) => {
	const response = await axios.post('/api/report/log/userlist',{...state});
  const data = await response.data;
  return data;
});


const usersLogSlice = createSlice({
  name: 'reportApp/userlogs',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getUsersLog.fulfilled]: (state, action) => action.payload,
  },
});


export const selectusersLog = ({ reportApp }) =>  reportApp.userlogs;

export default usersLogSlice.reducer;