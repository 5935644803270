import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';


export const getProfiles = createAsyncThunk(
  'projectDashboardApp/profiles/getProfiles',
  async () => {
    const response = await axios.post('/api/profile/profile-dashboard');
    return response.data;
  }
);


const profileSlice = createSlice({
  name: 'projectDashboardApp/profiles',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getProfiles.fulfilled]: (state, action) => action.payload,
  },
});

export const selectProfiles = ({ projectDashboardApp }) => projectDashboardApp.profiles;

export default profileSlice.reducer;