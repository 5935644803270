import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

//import { selectOrdersSearchText, setOrdersSearchText } from '../store/ordersSlice';

function UserLogsHeader(props) {

  const Logusers = typeof props.logsuser!="undefined"?props.logsuser:[];
  const dispatch = useDispatch();
  const searchText = '';//useSelector(selectOrdersSearchText);
  const [logsdata, setLogData] = useState([]);
  const [user, setUser] = useState("");
  const getuserlogfnc = typeof props.getuserlogfnc!="undefined"?props.getuserlogfnc:"";
  useEffect(() => {
    setLogData(Logusers);
  }, [Logusers]);

   const handleChange = (event) => {
    setUser(event.target.value);
    dispatch(getuserlogfnc({userid:event.target.value}));
  };


  return (
    <div className="flex flex-col sm:flex-row flex-1 w-full space-y-8 sm:space-y-0 items-center justify-between py-32 px-24 md:px-32">
      <Typography
        component={motion.span}
        initial={{ x: -20 }}
        animate={{ x: 0, transition: { delay: 0.2 } }}
        delay={300}
        className="flex text-24 md:text-32 font-extrabold tracking-tight"
      >
        User Logs
      </Typography>

      <div className="flex flex-1 items-center justify-end space-x-8 w-full sm:w-auto">
        <Box sx={{ minWidth:250 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">User</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={user}
                label="User"
                onChange={handleChange}
              >
                <MenuItem value=""><em>All User</em></MenuItem>
                {logsdata.map((n,index)=>{ 
                  return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>;
                })}
                
              </Select>
            </FormControl>
        </Box>
      </div>
    </div>
  );
}

export default UserLogsHeader;