import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getPdfsLog = createAsyncThunk('reportApp/pdfs/get', async () => {
  const response = await axios.post('/api/report/download/pdf/userlist');
  const data = await response.data;
  return data;
});

const pdfsSlice = createSlice({
  name: 'reportApp/pdfs',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getPdfsLog.fulfilled]: (state, action) => action.payload,
  },
});


export const selectpdfs = ({ reportApp }) =>  reportApp.pdfs;

export default pdfsSlice.reducer;