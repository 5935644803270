import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';

export const getMaster = createAsyncThunk(
  'academyApp/masters/getMaster',
  async (params,{ getDataField }) => {
      
      const response = await axios.get(`/api/masters/list/frontend`);
      const data = await response.data;
      return data;
    
    }
);


export const selectMaster = ({ academyApp }) => academyApp.masters;

const masterSlice = createSlice({
  name: 'academyApp/masters',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getMaster.pending]: (state, action) => null,
    [getMaster.fulfilled]: (state, action) => action.payload
  },
});


export default masterSlice.reducer;