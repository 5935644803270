import { createAsyncThunk,  createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getShareProfiles = createAsyncThunk('reportApp/shareprofiles/get', async () => {
  const response = await axios.post('/api/report/shareprofilelist');
  const data = await response.data;
  return data;
});


const shareProfilesSlice = createSlice({
  name: 'reportApp/shareprofiles',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getShareProfiles.fulfilled]: (state, action) => action.payload,
  },
});


export const selectshareprofiles = ({ reportApp }) =>  reportApp.shareprofiles;

export default shareProfilesSlice.reducer;