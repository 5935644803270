import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getFields = createAsyncThunk('dataFieldApp/fields/getFields', async () => {
  const response = await axios.get('/api/datafield/list');
  const data = await response.data;
  return data;
});

export const removeFields = createAsyncThunk(
  'dataFieldApp/fields/removeFields',
  async (fieldIds, { dispatch, getState }) => {
    await axios.post('/api/datafield/delete', { dataFieldId: fieldIds });
    return fieldIds;
  }
);

const fieldsAdapter = createEntityAdapter({});

export const { selectAll: selectFields, selectById: selectFieldById } = fieldsAdapter.getSelectors(
  (state) => state.dataFieldApp.fields
);

const fieldsSlice = createSlice({
  name: 'dataFieldApp/fields',
  initialState: fieldsAdapter.getInitialState({
    searchText: '',
  }),
  reducers: {
    setOrdersSearchText: {
      reducer: (state, action) => {
        state.searchText = action.payload;
      },
      prepare: (event) => ({ payload: event.target.value || '' }),
    },
  },
  extraReducers: {
    [getFields.fulfilled]: fieldsAdapter.setAll,
    [removeFields.fulfilled]: (state, action) => fieldsAdapter.removeMany(state, action.payload),
  },
});

export const { setOrdersSearchText } = fieldsSlice.actions;

export const selectOrdersSearchText = ({ dataFieldApp }) => dataFieldApp.fields.searchText;

export default fieldsSlice.reducer;
