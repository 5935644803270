import { lazy } from 'react';
import ContactView from './contact/ContactView';

const ContactsApp = lazy(() => import('./ContactsApp'));

const ContactsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'profile/share/:profileid',
      element: <ContactsApp />,
      children: [
        {
          path: 'view/:id',
          element: <ContactView />,
        },
      ],
    },
  ],
};

export default ContactsAppConfig;
