import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { lighten, ThemeProvider } from '@mui/material/styles';
import { selectMainThemeDark } from 'app/store/fuse/settingsSlice';
import { OutlinedInput } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useEffect } from 'react';
import { getPdfsLog, selectpdfs} from '../store/pdfsSlice';
import { styled } from '@mui/material/styles';
import PdfsHeader from './PdfsHeader';
import PdfsTable from './PdfsTable';
import FusePageSimple from '@fuse/core/FusePageSimple';


const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

function Pdfs() {
  
  const mainThemeDark = useSelector(selectMainThemeDark);
  const dispatch = useDispatch();
  const pdfsRecord = useSelector(selectpdfs);
  
  useEffect(() => {
    dispatch(getPdfsLog());
  }, [dispatch]);
  

  return (
    <Root
      header={<PdfsHeader logsuser={pdfsRecord?.pdflogs.users} getpdfslogfnc={getPdfsLog} />}
      content={<PdfsTable pdflogs={pdfsRecord?.pdflogs.records} />}
      scroll="content"
    />
  );
}

export default Pdfs;