import i18next from 'i18next';

import DashboardPage from './Dashboard';
import authRoles from '../../auth/authRoles';


const DashboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'dashboad',
      element: <DashboardPage />,
    },
  ],
};

export default DashboardConfig;
