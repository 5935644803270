import { lazy } from 'react';
import ContactView from './contact/ContactView';
import ContactForm from './contact/ContactForm';

const ContactsApp = lazy(() => import('./ContactsApp'));

const ContactsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'share/profile/:id',
      element: <ContactsApp />,
      children: [
        {
          path: 'view/:id',
          element: <ContactView />,
        },
        /*{
          path: ':id/share',
          element: <ContactForm />,
        },*/
      ],
    },
  ],
};

export default ContactsAppConfig;
