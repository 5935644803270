import FusePageSimple from '@fuse/core/FusePageSimple';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import withReducer from 'app/store/withReducer';
import _ from '@lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ProjectDashboardAppHeader from './ProjectDashboardAppHeader';
import reducer from './store';
import { getProfiles,selectProfiles } from './store/profileSlice';
import { getUsers, selectUsers } from './store/userSlice';
import HomeTab from './tabs/home/HomeTab';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    boxShadow: `inset 0 0 0 1px  ${theme.palette.divider}`,
  },
}));

function DashboardPage(props) {
  const dispatch = useDispatch();
  const profiles = useSelector(selectProfiles);
  const users = useSelector(selectUsers);

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    dispatch(getProfiles());
    dispatch(getUsers());
  }, [dispatch]);


  function handleChangeTab(event, value) {
    setTabValue(value);
  }

  /*if (_.isEmpty(widgets)) {
    return null;
  }*/

  return (
    <Root
      content={
        <div className="w-full p-12 pt-16 sm:pt-24 lg:ltr:pr-0 lg:rtl:pl-0">
          {tabValue === 0 && users!=null && <HomeTab users={users} />}
        </div>
      }
    />
  );
}

export default withReducer('projectDashboardApp', reducer)(DashboardPage);
