import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import FusePageSimple from '@fuse/core/FusePageSimple';
import DemoContent from '@fuse/core/DemoContent';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as yup from 'yup';
import _ from '@lodash';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';
import jwtService from '../../auth/services/jwtService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Route, Link, Routes, useParams} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  field_name: yup.string().required('You must enter data field name'),
  field_type: yup.string().required('You must select data field type'),
  order_no: yup.number(),
  default_value: yup.string().when('field_type',(value,schema)=>{
     if(value[0]==4 || value[0]==5 || value[0]==6) return schema.required('You must enter default value in comma separated value');
  })
});

const defaultValues = {
  field_name: '',
  field_type: '',
  default_value: '',
  order_no: 0,
  required:'0',
  visibility:'1',
  isActive:''
};

function  EditDataFieldPage(props) {

  const navigate = useNavigate();

  const {id} = useParams();

  console.log({id});
  

  const { control, formState, handleSubmit, reset,setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema)
  });

  const { isValid, dirtyFields, errors, setError } = formState;


   function getDataFieldData(id) {
    jwtService
      .getSingleFieldData(id)
      .then((result) => {
         console.log("Edit Data Field");

         let default_values = result.default_value!=null?result.default_value:"";

         setValue('field_name',result.field_name);
         setValue('field_type',result.field_type);
         setValue('default_value',default_values);
         setValue('order_no',result.order_no);
         setValue('required',String(result.required)); 
         setValue('visibility',String(result.visibility));
         setValue('isActive',result.isActive);
         //setRecord(result);
      })
      .catch((_errors) => {
        console.log("saveError");
        console.log({_errors});
        return false;
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }

  useEffect(() => {
    getDataFieldData(id);
    //dispatch(getOrders()).then(() => setLoading(false));
  }, [id]);

  function onSubmit(formData) {

    /*console.log({formData});
    return false;*/

    jwtService
      .updateDataField(formData,id)
      .then((result) => {
        console.log("saved");
        console.log({result});
        navigate('/admin/data-field');
      })
      .catch((_errors) => {
        console.log("saveError");
        console.log({_errors});
        return false;
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }
  // const { t } = useTranslation('examplePage');

  return (
    <Root
      header={
        <div className="p-24">
          <h4>Edit Data Field</h4>
        </div>
      }
      content={
       
        <div className="p-24">
          
          <form
            name="registerForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="field_name"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="datafield-type">DataField Name <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-12"
                  autoFocus
                  type="name"
                  error={!!errors.field_name}
                  helperText={errors?.field_name?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />

            <Controller
              name="field_type"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="datafield-type">DataField Type <span className="text-red-600">*</span></FormLabel>
                <Select
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="name"
                  error={!!errors.field_type}
                  helperText={errors?.field_type?.message}
                  variant="outlined"
                  required
                  fullWidth
                >
                  <MenuItem value={1}>Input</MenuItem>
                  <MenuItem value={2}>Number</MenuItem>
                  <MenuItem value={3}>Textarea</MenuItem>
                  <MenuItem value={4}>Select</MenuItem>
                  <MenuItem value={5}>Multi Select</MenuItem>
                  <MenuItem value={6}>Radio</MenuItem>
                  <MenuItem value={7}>Date Picker</MenuItem>
                  <MenuItem value={8}>DateTime Picker</MenuItem>
                  <MenuItem value={9}>Color Picker</MenuItem>
                </Select>
                </FormControl>
              )}
            />

            <Controller
              name="default_value"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="default-value-id">Default Value</FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  type="name"
                  error={!!errors.default_value}
                  helperText={errors?.default_value?.message}
                  variant="outlined"
                  multiline
                  rows={4}
                />
                </FormControl>
              )}
            />

            <Controller
              name="order_no"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="order-no-id">Order No</FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="number"
                  error={!!errors.order_no}
                  helperText={errors?.order_no?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />

            <Controller
              name="required"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="required-id">Required <span className="text-red-600">*</span> </FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'1'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'1'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'0'} control={<Radio />} label="No" />
                </RadioGroup>
                </FormControl>
              )}
            />

            <Controller
              name="visibility"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="visibility-id">Visibility <span className="text-red-600">*</span> </FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'1'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'1'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'0'} control={<Radio />} label="No" />
                </RadioGroup>
                </FormControl>
              )}
            />

          <Controller
              name="isActive"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="datafield-type">Status <span className="text-red-600">*</span> </FormLabel>
                <Select
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="name"
                  error={!!errors.isActive}
                  helperText={errors?.isActive?.message}
                  variant="outlined"
                  required
                  fullWidth
                >
                  <MenuItem value={'0'}>InActive</MenuItem>
                  <MenuItem value={'1'}>Active</MenuItem>
                </Select>
                </FormControl>
              )}
            />

     
            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-24"
              aria-label="Register"
              type="submit"
              size="large"
            >
              Update
            </Button>
          </form>

        </div>
      }
      scroll="content"
    />
  );
}

export default EditDataFieldPage;