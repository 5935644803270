import i18next from 'i18next';

import ProfilePage from './Profile';
import AddProfilePage from './AddProfile';
import EditProfilePage from './EditProfile';
import authRoles from '../../auth/authRoles';
import UnregisteredProfilePage from './UnregisteredProfile';

const ProfileConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.operator,
  routes: [
    {
      path: 'admin/profiles/:searchby?',
      element: <ProfilePage />,
    },
    {
      path: 'admin/add/profile',
      element: <AddProfilePage />,
    },
    {
      path: 'admin/unregistered-profile',
      element: <UnregisteredProfilePage />,
    },
    {
      path: 'admin/edit/profile/:id',
      element: <EditProfilePage />,
    }
  ],
};

export default ProfileConfig;