import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';


export const getStateCity = createAsyncThunk(
  'academyApp/statecities/getStateCity',
  async (params,{ getState }) => {
      const response = await axios.post(`/api/profile/get/statecity`,{stateId:params});
      const data = await response.citydata;
      return data;
    
    }
);

export const selectStateCity = ({ academyApp }) => academyApp.statecities;

const stateCitiesSlice = createSlice({
  name: 'academyApp/statecities',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getStateCity.pending]: (state, action) => null,
    [getStateCity.fulfilled]: (state, action) => action.payload
  },
});


export default stateCitiesSlice.reducer;