import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { memo } from 'react';
import { Link } from 'react-router-dom';


function OverdueWidget(props) {

  const styles = {
    anchor: {
      textDecoration:"none"
    }
  }

  return (
    <Paper className="flex flex-col flex-auto shadow rounded-2xl overflow-hidden">
      <div className="flex items-center justify-between px-8 pt-12">
         &nbsp;
      </div>
      <div className="text-center mt-8">
      <Link style={styles.anchor} to={'/admin/profiles'}>
        <Typography className="text-7xl sm:text-8xl font-bold tracking-tight leading-none text-red-500">
          {props.totalprofile}
        </Typography>
        <Typography className="text-lg font-medium text-red-600">Profiles</Typography>
        </Link>
      </div>
      <Typography
        className="flex items-baseline justify-center w-full mt-20 mb-24"
        color="text.secondary"
      >
        &nbsp;
      </Typography>
    </Paper>
  );
}

export default memo(OverdueWidget);
