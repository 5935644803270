import { combineReducers } from '@reduxjs/toolkit';
import course from './courseSlice';
import courses from './coursesSlice';
import categories from './categoriesSlice';
import states from './statesSlice';
import statecities from './citiesSlice';
import masters from './masterSlice';
import searchprofiles from './searchProfileSlice';

const reducer = combineReducers({
  states,
  statecities,
  categories,
  courses,
  course,
  masters,
  searchprofiles
});

export default reducer;
