import { motion } from 'framer-motion';
import SummaryWidget from './widgets/SummaryWidget';
import OverdueWidget from './widgets/OverdueWidget';
import IssuesWidget from './widgets/IssuesWidget';
import FeaturesWidget from './widgets/FeaturesWidget';
import ScheduleWidget from './widgets/ScheduleWidget';
import GithubIssuesWidget from './widgets/GithubIssuesWidget';
import LatestTableWidget from './widgets/LatestTableWidget';

function HomeTab(props) {
  const container = {
    show: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-24 w-full min-w-0 p-24"
      variants={container}
      initial="hidden"
      animate="show"
    >
      <motion.div variants={item}>
        <SummaryWidget  totaluser={props.users.totaluser} />
      </motion.div>
      <motion.div variants={item}>
        <OverdueWidget totalprofile={props.users.profiles} />
      </motion.div>
      <motion.div variants={item}>
        <IssuesWidget shareprofile={props.users.shareprofile} />
      </motion.div>
      <motion.div variants={item}>
        <FeaturesWidget seachprofile={props.users.searchprofile} />
      </motion.div>
      <motion.div variants={item} className="sm:col-span-2 md:col-span-4">
        <GithubIssuesWidget />
      </motion.div>
      <motion.div variants={item} className="sm:col-span-2 md:col-span-4">
        <LatestTableWidget />
      </motion.div>
    </motion.div>
  );
}

export default HomeTab;
