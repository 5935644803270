import FusePageSimple from '@fuse/core/FusePageSimple';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select  from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import * as yup from 'yup';
import _ from '@lodash';
import FormHelperText from '@mui/material/FormHelperText';
import jwtService from '../../auth/services/jwtService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { lighten, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { getState, selectState } from './store/statesSlice';
import { getStateCity, selectStateCity } from './store/citiesSlice';
import { getDataField, selectDataField} from './store/datafieldsSlice';
import { getMaster, selectMaster} from './store/masterSlice';
import { useDispatch, useSelector } from 'react-redux';
import jwtServiceConfig from '../../auth/services/jwtService/jwtServiceConfig';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));


/**
 * Form Validation Schema
 */

function AddProfilePage(props) {

 const dispatch = useDispatch(); 
 const stateData = useSelector(selectState);
 const cityData = useSelector(selectStateCity);
 const datafieldData = useSelector(selectDataField);
 const masterData = useSelector(selectMaster);
 const navigate = useNavigate();
 const [redirect, setRedirectValue] = useState(false);
 const [emailfield, setEmailFieldValue] = useState(false);
 const [mobilefield,setMobileFieldValue] = useState(false);
 const [statelists, setStateList] = useState([]);
 const [citylists, setCityList] = useState([]);
 const [datafieldlists, setDataFieldList] = useState([]);
 const [masterlists, setMasterList] = useState([]);
 const [masterlists1, setMaster1List] = useState([]);
 const [bodydistext, setBodyDisText] = useState('No');

 const heightValue = ['4.0',4.1,4.2,4.3,4.4,4.5,4.6,4.7,4.8,4.9,'4.10',4.11,'5.0',5.1,5.2,5.3,5.4,5.5,5.6,5.7,5.8,5.9,'5.10',5.11,'6.0',6.1,6.2,6.3,6.4,6.5,6.6,6.7,6.8,6.9,'6.10',6.11,'7.0',7.1,7.2,7.3,7.4,7.5,7.6,7.7,7.8,7.9,'7.10',7.11];
 

  useEffect(() => {
    if(redirect==true) {
      navigate('/admin/profiles'); 
    }  
  }, [redirect]);

  useEffect(() => {
    dispatch(getState());
    dispatch(getDataField());
    dispatch(getMaster());
  }, [dispatch]);

  useEffect(() => {
    if(stateData!=null) {
      setStateList(stateData);
    }
    if(cityData!=null) {
      setCityList(cityData);
    }
    if(datafieldData!=null){
      const activedatafield = datafieldData.filter((data)=> data.isActive==1);
      setDataFieldList(activedatafield);
    }
    if(masterData!=null){
      const gotradata = masterData.filter((data)=> data.type==1 && data.isActive==1);
      const qualificationdata = masterData.filter((data)=> data.type==2 && data.isActive==1);
      setMasterList(gotradata);
      setMaster1List(qualificationdata);
    }
  }, [stateData,cityData,datafieldData,masterData]);

  
  const customFields = {};
  const customFieldSchemes = {};

  if(datafieldlists.length>0){
      for(let i=0;i<datafieldlists.length;i++){
          customFields[`data_field_${datafieldlists[i].id}`]='';
         if(datafieldlists[i].required==1){
            customFieldSchemes[`data_field_${datafieldlists[i].id}`]=yup.string().required(datafieldlists[i].name.charAt(0).toUpperCase() + datafieldlists[i].name.slice(1)+" is Required Field");
         }          
      }
  }

  const schema = yup.object().shape({
    ...customFieldSchemes,
    first_name: yup.string().required('You must enter first name'),
    last_name: yup.string().required('You must enter last name'),
    email: yup.string().email('Invalid Email').required('You must enter email')
    .when((value,schema)=>{
       if(value!='' && emailfield) return schema.test('Unique Email','Email already in use', 
        function(value){return new Promise((resolve, reject) => {
            //setEmailFieldValue(false);
            axios.post(jwtServiceConfig.checkProfileType, {'value': value,'type':'email'})
                  .then(res =>  {  if(res.count>0) { resolve(false)} resolve(true)})
        })});
    }),
    mobile: yup.string().required('you must enter the mobile number').matches(/^[0-9]+$/, "Must be only digits").min(10, 'Must be exactly 10 digits').max(10, 'Must be exactly 10 digits')
    .when((value,schema)=>{
      if(value!='' && mobilefield) return schema.test('Unique Mobile','Mobile already in use', 
       function(value){return new Promise((resolve, reject) => {
           //setMobileFieldValue(false);
           axios.post(jwtServiceConfig.checkProfileType, {'value': value,'type':'mobile'})
                 .then(res => { if(res.count>0){resolve(false)} resolve(true)})
       })});
    }),
    father_name: yup.string().required('You must enter father name'),
    mother_name: yup.string().required('You must enter mother name'),
    dob: yup.date().required('You must enter date of birth'),
    dobtime: yup.string().required('You must enter time of birth'),
    occupation: yup.string().required('You must enter occupation'),
    education: yup.string().required('You must enter education'),
    height: yup.string().required('You must select height'),
    budget: yup.string().required('You must select budget'),
    budget_unit: yup.string().required('You must select budget unit'),
    manglik: yup.mixed().oneOf(['Yes','No','NA']).required('You must select manglik'),
    address: yup.string().required('You must enter address'),
    hobbies: yup.string().required('You must enter hobbies'),
    gotra: yup.string().required('You must select gotra'),
    qualification: yup.string().required('You must select qualification'),
    state_id: yup.string().required('You must select state'),
    city_id: yup.string().required('You must select city'), 
    profile_image: yup.mixed().test("required", "You need to provide a image", (file) => {
          if (file && file.length > 0) return true;
          return false;
        }).test("type", "Valid image type are 'jpg', 'gif', 'png', 'jpeg'", (value) => {
          return (
            value && value.length > 0 && (value[0].type === "image/jpeg" || value[0].type === "image/jpg" || value[0].type === "image/gif" || value[0].type === "image/png")
          );
        }).test("fileSize", "The file is too large", (value) => {
          return value && value.length > 0 && value[0].size <= 5242880; // 10,485,760 Bytes
        })    
  });

  {/* other_image_1: yup.mixed().test("required", "You need to provide a image", (file) => {
          if (file && file.length > 0) return true;
          return false;
        }).test("type", "Valid image type are 'jpg', 'gif', 'png', 'jpeg'", (value) => {
          return (
            value && value.length > 0 && (value[0].type === "image/jpeg" || value[0].type === "image/jpg" || value[0].type === "image/gif" || value[0].type === "image/png")
          );
        }).test("fileSize", "The file is too large", (value) => {
          return value && value.length > 0 && value[0].size <= 1024 * 1024;
        }),
    other_image_2: yup.mixed().test("required", "You need to provide a image", (file) => {
          if (file && file.length > 0) return true;
          return false;
        }).test("type", "Valid image type are 'jpg', 'gif', 'png', 'jpeg'", (value) => {
          return (
            value && value.length > 0 && (value[0].type === "image/jpeg" || value[0].type === "image/jpg" || value[0].type === "image/gif" || value[0].type === "image/png")
          );
        }).test("fileSize", "The file is too large", (value) => {
          return value && value.length > 0 && value[0].size <= 1024 * 1024;
        }),
    other_image_3: yup.mixed().test("required", "You need to provide a image", (file) => {
          if (file && file.length > 0) return true;
          return false;
        }).test("type", "Valid image type are 'jpg', 'gif', 'png', 'jpeg'", (value) => {
          return (
            value && value.length > 0 && (value[0].type === "image/jpeg" || value[0].type === "image/jpg" || value[0].type === "image/gif" || value[0].type === "image/png")
          );
        }).test("fileSize", "The file is too large", (value) => {
          return value && value.length > 0 && value[0].size <= 1024 * 1024;
        }), */}


  const defaultValues = {
    first_name: '',
    last_name:'',
    father_name:'',
    mother_name:'',
    email:'',
    mobile:'',
    dobtime:'',
    gender:'Male',
    manglik:'NA',
    marriage_status:'single',
    body_disable:'No',
    body_disable_text:'',
    occupation:'',
    education:'',
    height:'',
    budget:'',
    budget_unit:'',
    hobbies:'',
    state_id:'',
    city_id:'',
    address:'',
    reference:'',
    gotra:'',
    qualification:'',
    ...customFields
  };
  

  const { register,
    unregister,
    getValues,
    trigger,
    clearErrors,
    resetField,
    control, formState, handleSubmit, reset , watch,setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const images = watch('images');

  const { isValid, dirtyFields, errors, setError } = formState;

  function onSubmit(formData) {
    
    const data_fields = [];
    datafieldlists.map((element,index,array)=>{
      data_fields.push(element.id);
    });
    console.warn({data_fields});
    formData['data_fields']=data_fields;
    console.warn("OnSubmit Form Profile Value >>>>");
    formData.profile_image = formData.profile_image[0];
    // Getting Image Record From Form Fields 
    /*
    formData.profile_image = formData.profile_image[0];
    let other_image_array = formData.other_image;
    formData.other_image_length = other_image_array.length;
    if(other_image_array.length>0) {
       for (const key in other_image_array) {
        if (Object.hasOwnProperty.call(other_image_array, key)) {
           console.warn(other_image_array[key]);
           formData["other_image_"+key] = other_image_array[key];
        }
       }
       console.warn({other_image_array});
    } */

    //formData.other_image_1 = formData.other_image_1[0];
    //formData.other_image_2 = formData.other_image_2[0];
    //formData.other_image_3 = formData.other_image_3[0];

    /* 
       return false; */

    var date = formData.dob; 
    var dateString = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 )) .toISOString() .split("T")[0];
    formData.dob = dateString;
      
    jwtService
      .createProfile(formData)
      .then((result) => {
          navigate('/admin/profiles');
          setRedirectValue(true);
      })
      .catch((_errors) => {
        console.log("saveError");
        console.log({_errors});
        return false;
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }

  const handleChange = (event) => {
      let state_id_val = event.target.value;
      setValue('state_id',state_id_val);
      if(state_id_val!='')
        dispatch(getStateCity(state_id_val));
      else
        setCityList([]);
  };

  const bodyHandleChange = (event) => {
      //console.warn("bodyHandleChane");
      setBodyDisText(event.target.value);
  }
  
  const handleBlur = (event) => {
       if(event.target.name=='email') {
          setEmailFieldValue(true);
       } else if(event.target.name=='mobile'){
          setMobileFieldValue(true);
       }    
  }

  var maxBirthdayDate = new Date();
  maxBirthdayDate.setFullYear( maxBirthdayDate.getFullYear() - 18,11,31);

  
  return (
    <Root
      header={
        <div className="p-24">
          <h3>Add Profile</h3>
        </div>
      }
      content={
        <div className="p-24">
          
          <form
            name="profileRegisterForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
              <Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
               
              <Grid item xs={6}>
                  <Controller
                    name="first_name"
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ width: '100%' }}>
                      <FormLabel id="first_name_id">First Name <span className="text-red-600">*</span></FormLabel>
                      <TextField
                        {...field}
                        className="mb-24"
                        autoFocus
                        type="text"
                        error={!!errors.first_name}
                        helperText={errors?.first_name?.message}
                        variant="outlined"
                        required
                        tabIndex={1}
                      />
                      </FormControl>
                    )}
                  />   
              </Grid>

              <Grid item xs={6}>
                  <Controller
                    name="last_name"
                    control={control}
                    render={({ field }) => (
                      <FormControl sx={{ width: '100%' }}>
                      <FormLabel id="last_name_id">Last Name <span className="text-red-600">*</span></FormLabel>
                      <TextField
                        {...field}
                        className="mb-24"
                        autoFocus
                        type="text"
                        error={!!errors.last_name}
                        helperText={errors?.last_name?.message}
                        variant="outlined"
                        required
                      />
                      </FormControl>
                    )}
                  />   
              </Grid>

        <Grid item xs={6}>
          <Controller
              name="father_name"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="father_name_id">Father Name <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.father_name}
                  helperText={errors?.father_name?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />    
        </Grid>
        <Grid item xs={6}>

           <Controller
              name="mother_name"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="mother_name_id">Mother Name <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.mother_name}
                  helperText={errors?.mother_name?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
             <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="email-id">Email <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  onBlur={handleBlur}
                />
                </FormControl>
              )}
            />   
        </Grid>
        
        <Grid item xs={6}>
              <Controller
              name="mobile"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="mobile-id">Mobile <span className="text-red-600">*</span></FormLabel>
                 <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="number"
                  error={!!errors.mobile}
                  helperText={errors?.mobile?.message}
                  variant="outlined"
                  required
                  fullWidth
                  onBlur={handleBlur}
                />
                </FormControl>
              )}
            />                  
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="dob"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="dob-id">Date of Birth <span className="text-red-600">*</span></FormLabel>
                <DatePicker
                  {...field}
                  className="mb-24"
                  autoFocus
                  variant="outlined"
                  required
                  fullWidth
                  maxDate={maxBirthdayDate}
                />
                <FormHelperText error={!!errors.dob}>{errors?.dob?.message}</FormHelperText>
                </FormControl>
              )}
            />                   
        </Grid>
        <Grid item xs={6}>
           <Controller
              name="dobtime"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="dobtime-id">Time of Birth <span className="text-red-600">*</span> </FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="time"
                  error={!!errors.dobtime}
                  helperText={errors?.dobtime?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />                   
        </Grid>
        
        <Grid item xs={6}>
           <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="gender-id">Gender <span className="text-red-600">*</span></FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'Male'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'Male'} control={<Radio />} label="Male" />
                  <FormControlLabel value={'Female'} control={<Radio />} label="Female" />
                </RadioGroup>
                </FormControl>
              )}
            /> 
        </Grid>


        
        <Grid item xs={6}>
            <Controller
              name="manglik"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="manglik-id">Manglik <span className="text-red-600">*</span></FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'NA'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'Yes'} control={<Radio />} label="Yes" />
                  <FormControlLabel value={'No'} control={<Radio />} label="No" />
                  <FormControlLabel value={'NA'} control={<Radio />} label="NA" />
                </RadioGroup>
                </FormControl>
              )}
            />     
        </Grid>

        <Grid item xs={6}>
            <Controller
              name="marriage_status"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="marriage_status_id">Marriage Status <span className="text-red-600">*</span></FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'single'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'single'} control={<Radio />} label="Single" />
                  <FormControlLabel value={'married'} control={<Radio />} label="Married" />
                  <FormControlLabel value={'widowed'} control={<Radio />} label="Widowed" />
                  <FormControlLabel value={'divorced'} control={<Radio />} label="Divorced" />
                </RadioGroup>
                </FormControl>
              )}
            />     
        </Grid>

        <Grid item xs={6}>
            <Controller
              name="body_disable"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="body_disable_id">Body Disable <span className="text-red-600">*</span></FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'No'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel onChange={bodyHandleChange} value={'Yes'} control={<Radio />} label="Yes" />
                  <FormControlLabel onChange={bodyHandleChange} value={'No'} control={<Radio />} label="No" />
                </RadioGroup>
                </FormControl>
              )}
            />     
        </Grid>
        
        {bodydistext=='Yes'?
        <Grid item xs={6}>
            <Controller
              name="body_disable_text"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="body_disable_text_id">Body Disable Part <span className="text-red-600">*</span></FormLabel>
                 <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.body_disable_text}
                  helperText={errors?.body_disable_text?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />     
        </Grid>:''}

        <Grid item xs={6}>
           <Controller
              name="occupation"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="occupation-id">Occupation <span className="text-red-600">*</span></FormLabel>
                 <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.occupation}
                  helperText={errors?.occupation?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            /> 
        </Grid>
        <Grid item xs={6}>
            <Controller
              name="education"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="education-id">Education <span className="text-red-600">*</span></FormLabel>
                 <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.education}
                  helperText={errors?.education?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />                  
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="height"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="height-id">Height <span className="text-red-600">*</span> (Ft.In.)</FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.height}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select Height</em></MenuItem>
                {heightValue.map((n,index)=>{ 
                  return <MenuItem key={index} value={n}>{n}</MenuItem>;
                })}
                </Select>
                <FormHelperText error={!!errors.height}>{errors?.height?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>
        
        <Grid item xs={6}>
           <Controller
              name="budget"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '60%' }}>
                <FormLabel id="budget-id">Budget <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.budget}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select Budget</em></MenuItem>
                {[...Array(100)].map((e, i) => {
                  return <MenuItem key={i} value={i+1}>{i+1}</MenuItem>;
                })}
                </Select>
                <FormHelperText error={!!errors.budget}>{errors?.budget?.message}</FormHelperText>
                </FormControl>
              )}
            /> &nbsp;
            <Controller
              name="budget_unit"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '30%' }}>
                <FormLabel id="budget_unit_id">Budget Unit <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.budget_unit}
                 
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select Budget Unit</em></MenuItem>
                <MenuItem key={'Cr'} value={'Cr'}>Cr</MenuItem>
                <MenuItem key={'Lakh'} value={'Lakh'}>Lakh</MenuItem>
                </Select>
                <FormHelperText error={!!errors.budget_unit}>{errors?.budget_unit?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
            <Controller
              name="hobbies"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="hobbies-id">Hobbies <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.hobbies}
                  helperText={errors?.hobbies?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="gotra"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="gotra-id">Gotra <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.gotra}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select Gotra</em></MenuItem>
                
                {masterlists.map((n,index)=>{ 
                  return <MenuItem key={index} value={n.name}>{n.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={!!errors.gotra}>{errors?.gotra?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="qualification"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="qualification-id">Qualification <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.height}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select Qualification</em></MenuItem>
                {masterlists1.map((n,index)=>{ 
                  return <MenuItem key={index} value={n.name}>{n.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={!!errors.qualification}>{errors?.qualification?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="state_id"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="state-id">State <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.state_id}
                 variant="outlined"
                 required
                 fullWidth
                 onChange={handleChange}
              >
                <MenuItem value=''><em>Select State</em></MenuItem>
                
                {statelists.map((n,index)=>{ 
                  return <MenuItem key={index} value={n.id}>{n.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={!!errors.state_id}>{errors?.state_id?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
           <Controller
              name="city_id"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="city-id">City <span className="text-red-600">*</span></FormLabel>
                <Select
                {...field}
                 labelId="demo-simple-select-label"
                 error={!!errors.height}
                 variant="outlined"
                 required
                 fullWidth
              >
                <MenuItem value=''><em>Select City</em></MenuItem>
                {citylists.map((n,index)=>{ 
                  return <MenuItem key={index} value={n.id}>{n.city}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={!!errors.city_id}>{errors?.city_id?.message}</FormHelperText>
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="address-id">Address <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mt-8 mb-16"
                  id="address"
                  label="Address"
                  type="text"
                  multiline
                  rows={5}
                  variant="outlined"
                  fullWidth
                  error={!!errors.address}
                  helperText={errors?.address?.message}
                />
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
            <Controller
              name="reference"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }}>
                <FormLabel id="reference-id">Reference</FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.reference}
                  helperText={errors?.reference?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />
        </Grid>

        <Grid item xs={6}>
        <FormControl sx={{ width: '100%' }}>
                <FormLabel id="profile_image-id">Profile Image <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...register("profile_image")}
                  className="mb-24"
                  autoFocus
                  type="file"
                  error={!!errors.profile_image}
                  helperText={errors?.profile_image?.message}
                  variant="outlined"
                  required
                  fullWidth
                  accept="image/jpeg,image/gif,image/png"
                />
                </FormControl>                    
        </Grid>

        <Grid item xs={6}>
        <FormControl sx={{ width: '100%' }}>
                <FormLabel id="other_image-id">Other Image </FormLabel>
                <TextField
                  {...register("other_image")}
                  className="mb-24"
                  autoFocus
                  type="file"
                  error={!!errors.other_image}
                  helperText={errors?.other_image?.message}
                  variant="outlined"
                  required
                  fullWidth
                  inputProps={{
                    multiple: true
                  }}
                  accept="image/jpeg,image/gif,image/png"
                />
                </FormControl>                    
        </Grid>

       {/* 
        <Grid item xs={6}>
        <FormControl sx={{ width: '100%' }}>
                <FormLabel id="other_image_1-id">Other Image-1 </FormLabel>
                <TextField
                  {...register("other_image_1[]")}
                  className="mb-24"
                  autoFocus
                  type="file"
                  error={!!errors.other_image_1}
                  helperText={errors?.other_image_1?.message}
                  variant="outlined"
                  required
                  fullWidth
                  inputProps={{
                    multiple: true
                  }}
                  accept="image/jpeg,image/gif,image/png,application/pdf"
                />
                </FormControl>                    
        </Grid>
        
        <Grid item xs={6}>
        <FormControl sx={{ width: '100%' }}>
                <FormLabel id="other_image_2-id">Other Image-2 </FormLabel>
                <TextField
                  {...register("other_image_2")}
                  className="mb-24"
                  autoFocus
                  type="file"
                  error={!!errors.other_image_2}
                  helperText={errors?.other_image_2?.message}
                  variant="outlined"
                  required
                  fullWidth
                  accept="image/jpeg,image/gif,image/png,application/pdf"
                />
                </FormControl>                    
        </Grid>

        <Grid item xs={6}>
        <FormControl sx={{ width: '100%' }}>
                <FormLabel id="other_image_3-id">Other Image-3 </FormLabel>
                <TextField
                  {...register("other_image_3")}
                  className="mb-24"
                  autoFocus
                  type="file"
                  error={!!errors.other_image_3}
                  helperText={errors?.other_image_3?.message}
                  variant="outlined"
                  required
                  fullWidth
                  accept="image/jpeg,image/gif,image/png,application/pdf"
                />
                </FormControl>                    
        </Grid>
                */}
        {
                datafieldlists && datafieldlists.map((element,index,array)=>{
                  let dataFieldValues = element.default_value!=null?element.default_value.split(","):[];
                   return <Grid item xs={6}>
                              <Controller
                                name={"data_field_"+element.id}
                                control={control}
                                render={({ field }) => (
                                  <FormControl sx={{ width: '100%' }}>
                                  <FormLabel id={element.name.toLowerCase()+"_id"}>{element.name.charAt(0).toUpperCase() + element.name.slice(1)} {element.required==1?<span className="text-red-600">*</span>:''}</FormLabel>

                                  {element.type==1?<TextField
                                    {...field}
                                    className="mb-24"
                                    autoFocus
                                    type="text"
                                    error={!!errors['data_field_'+element.id]}
                                    helperText={errors?.['data_field_'+element.id]?.message}
                                    variant="outlined"
                                    required
                                  />:''}
                                  {element.type==2?<TextField
                                    {...field}
                                    className="mb-24"
                                    autoFocus
                                    type="number"
                                    error={!!errors['data_field_'+element.id]}
                                    helperText={errors?.['data_field_'+element.id]?.message}
                                    variant="outlined"
                                    required
                                  />:''}
                                  {element.type==3?<TextField
                                    {...field}
                                    className="mb-24"
                                    autoFocus
                                    type="text"
                                    error={!!errors['data_field_'+element.id]}
                                    helperText={errors?.['data_field_'+element.id]?.message}
                                    variant="outlined"
                                    required
                                  />:''}
                                  {element.type==9?<TextField
                                    {...field}
                                    className="mb-24"
                                    autoFocus
                                    type="color"
                                    error={!!errors['data_field_'+element.id]}
                                    helperText={errors?.['data_field_'+element.id]?.message}
                                    variant="outlined"
                                    required
                                  />:''}
                                  { element.type==4?<><Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        error={!!errors['data_field_'+element.id]}
                                        variant="outlined"
                                        required
                                        fullWidth
                                      >
                                        <MenuItem value=''><em>Select {element.name.charAt(0).toUpperCase() + element.name.slice(1)}</em></MenuItem>
                                        {dataFieldValues.map((n,index)=>{ 
                                          return <MenuItem key={index+'_'+n} value={n}>{n}</MenuItem>;
                                        })}
                                        </Select>
                                    <FormHelperText error={!!errors['data_field_'+element.id]}>{errors?.['data_field_'+element.id]?.message}</FormHelperText></>:''}
                                    {element.type==5?<><Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        error={!!errors['data_field_'+element.id]}
                                        variant="outlined"
                                        required
                                        fullWidth
                                      >
                                        <MenuItem value=''><em>Select {element.name.charAt(0).toUpperCase() + element.name.slice(1)}</em></MenuItem>
                                        {dataFieldValues.map((n,index)=>{ 
                                          return <MenuItem key={index+'_'+n} value={n}>{n}</MenuItem>;
                                        })}
                                        </Select>
                                    <FormHelperText error={!!errors['data_field_'+element.id]}>{errors?.['data_field_'+element.id]?.message}</FormHelperText></>:''}
                                    {element.type==6?<><RadioGroup
                                                {...field}
                                                row
                                                aria-labelledby="demo-radio-buttons-group-label"
                                               
                                                name="radio-buttons-group"
                                              >{dataFieldValues.map((n,index)=>{ 
                                          return <FormControlLabel value={n.charAt(0).toUpperCase() + n.slice(1)} control={<Radio />} label={n.charAt(0).toUpperCase() + n.slice(1)} />;                                      ;
                                        })}</RadioGroup><FormHelperText error={!!errors['data_field_'+element.id]}>{errors?.['data_field_'+element.id]?.message}</FormHelperText></>:''}
                                    {element.type==7?<><TextField 
                                                        {...field}
                                                        className="mb-24"
                                                        autoFocus
                                                        type="date"
                                                        error={!!errors['data_field_'+element.id]}
                                                        helperText={errors?.['data_field_'+element.id]?.message}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                      /></>:''}
                                    {element.type==8?<><TextField 
                                                        {...field}
                                                        className="mb-24"
                                                        autoFocus
                                                        type="time"
                                                        error={!!errors['data_field_'+element.id]}
                                                        helperText={errors?.['data_field_'+element.id]?.message}
                                                        variant="outlined"
                                                        required
                                                        fullWidth
                                                      /></>:''}

                                  </FormControl>
                                )}
                                
                              />   
                          </Grid>;
                })
              }

        <Grid item xs={8}>
            
            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-24"
              aria-label="Register"
              type="submit"
              size="large"
            >
              Save
            </Button>
        </Grid>

      </Grid>
    </Box>   
          
          </form>

        </div>
      }
      scroll="content"
    />
  );
}

export default AddProfilePage;