import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { showMessage } from 'app/store/fuse/messageSlice';

export const getMaster = createAsyncThunk('masterApp/master/getMaster', async (masterId) => {
  const response = await axios.get(`/api/masters/${masterId}`);
  const data = await response.data;

  return data === undefined ? null : data;
});


export const saveMaster = createAsyncThunk('masterApp/master/saveMaster', async (masterdata, { getState, dispatch }) => {
    const response = await axios.post('/api/masters/save', masterdata);
    const data = await response.message;
    dispatch(showMessage({ message: data }));
    return data;
  });

export const updateMaster = createAsyncThunk('masterApp/master/updateMaster', async (masterdata, { getState, dispatch }) => {
    const masterid = masterdata.id;
    delete masterdata.id;
    const response = await axios.post(`/api/masters/update/${masterid}`, masterdata);
    const data = await response.message;
    dispatch(showMessage({ message: data }));
    return data;
  });

  
export const deleteMaster = createAsyncThunk('masterApp/master/deleteMaster', async (masterid, { getState, dispatch }) => {
    
    const response = await axios.post(`/api/masters/delete`,{masterId:masterid});
    const data = await response.message;
    dispatch(showMessage({ message: data }));
    return data;
  });


const masterSlice = createSlice({
  name: 'masterApp/master',
  initialState: null,
  reducers: {
    resetMaster: () => null,
  },
  extraReducers: {
    [getMaster.fulfilled]: (state, action) => action.payload,
    [saveMaster.fulfilled]: (state, action) => action.payload,
    [updateMaster.fulfilled]: (state, action) => action.payload,
  },
});

export const { resetMaster } = masterSlice.actions;

export const selectMaster = ({ masterApp }) => masterApp.master;

export default masterSlice.reducer;
