import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import * as yup from 'yup';
import _ from '@lodash';
import jwtService from '../../auth/services/jwtService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import jwtServiceConfig from '../../auth/services/jwtService/jwtServiceConfig';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  name: yup.string().required('You must enter user name'),
  email: yup.string().email('Invalid Email').required('You must enter email')
  .when((value,schema)=>{
     if(value!='') return schema.test('Unique Email','Email already in use', 
      function(value){return new Promise((resolve, reject) => {
          axios.post(jwtServiceConfig.checkUserEmail, {'email': value})
                .then(res => { if(res.message === 'This Email is already exist.'){resolve(false)} resolve(true)})
      })});
  }),
  mobile: yup.string().min(10).max(10).required('You must enter mobile number'),
  role: yup.mixed().oneOf(['0','2','3']).required('You must select user role'),
  gender: yup.mixed().oneOf(['Male','Female']).required('You must select user gender'),
  password: yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  passwordconfirmation: yup.string()
     .oneOf([yup.ref('password'), null], 'Passwords must match')
});

const defaultValues = {
  name: '',
  email: '',
  mobile: '',
  role: '0',
  gender:'Male',
  password:'',
  passwordconfirmation:''
};

function AddUserPage(props) {

 const navigate = useNavigate();
 const [redirect, setRedirectValue] = useState(false);
 
  useEffect(() => {
    if(redirect==true) {
      navigate('/admin/users'); 
    }  
  }, [redirect]);
  

  const { control, formState, handleSubmit, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors, setError } = formState;

  function onSubmit(formData) {

    delete formData.passwordconfirmation;

    jwtService
      .createUser(formData)
      .then((result) => {
          console.log("saved");
          console.log({result});
          navigate('/admin/users');
          setRedirectValue(true);
      })
      .catch((_errors) => {
        console.log("saveError");
        console.log({_errors});
        return false;
        _errors.forEach((error) => {
          setError(error.type, {
            type: 'manual',
            message: error.message,
          });
        });
      });
  }
  // const { t } = useTranslation('examplePage');

  return (
    <Root
      header={
        <div className="p-24">
          <h3>Add User</h3>
        </div>
      }
      content={
        <div className="p-24">
          
          <form
            name="userRegisterForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >

            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="name-id">User Name <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="text"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />

            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="email-id">User Email <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="email"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="password-id">Password <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />

            <Controller
              name="passwordconfirmation"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="passwordconfirmation-id">Password Confirmation <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="password"
                  error={!!errors.passwordconfirmation}
                  helperText={errors?.passwordconfirmation?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
                </FormControl>
              )}
            />

            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="role-id">Role <span className="text-red-600">*</span></FormLabel>
                <Select
                  {...field}
                  className="mb-24"
                  autoFocus
                  type="select"
                  error={!!errors.role}
                  helperText={errors?.role?.message}
                  variant="outlined"
                  required
                  fullWidth
                >
                  <MenuItem value={'0'}>User</MenuItem>
                  <MenuItem value={'2'}>Admin</MenuItem>
                  <MenuItem value={'3'}>Operator</MenuItem>
                </Select>
                </FormControl>
              )}
            />

            <Controller
              name="mobile"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="mobile-id">Mobile <span className="text-red-600">*</span></FormLabel>
                <TextField
                  {...field}
                  className="mb-24"
                  type="number"
                  error={!!errors.mobile}
                  helperText={errors?.mobile?.message}
                  variant="outlined"
                />
                </FormControl>
              )}
            />

            <Controller
              name="gender"
              control={control}
              render={({ field }) => (
                <FormControl sx={{ width: '175%' }}>
                <FormLabel id="gender-id">Gender <span className="text-red-600">*</span></FormLabel>
                 <RadioGroup
                  {...field}
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={'Male'}
                  name="radio-buttons-group"
                >
                  <FormControlLabel value={'Male'} control={<Radio />} label="Male" />
                  <FormControlLabel value={'Female'} control={<Radio />} label="Female" />
                </RadioGroup>
                </FormControl>
              )}
            />

     
            <Button
              variant="contained"
              color="secondary"
              className="w-full mt-24"
              aria-label="Register"
              type="submit"
              size="large"
            >
              Save
            </Button>
          </form>

        </div>
      }
      scroll="content"
    />
  );
}

export default AddUserPage;