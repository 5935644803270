import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';

export const getState = createAsyncThunk(
  'profilesApp/states/getState',
  async (params,{ getState }) => {
      
      const response = await axios.post(`/api/profile/get/states`);
      const data = await response.statedata;
      return data;
    
    }
);

export const getStateCity = createAsyncThunk(
  'profilesApp/statecities/getStateCity',
  async (params,{ getState }) => {
      
      const response = await axios.post(`/api/profile/get/statecity`);
      const data = await response.citydata;
      return data;
    
    }
);

export const selectState = ({ profilesApp }) => profilesApp.states;

const stateSlice = createSlice({
  name: 'profilesApp/states',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getState.pending]: (state, action) => null,
    [getState.fulfilled]: (state, action) => action.payload
  },
});


export default stateSlice.reducer;