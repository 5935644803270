import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getProfile = createAsyncThunk('profilesApp/profile/getProfile', async (profileId) => {
  const response = await axios.get(`/api/profile/${profileId}`);
  const data = await response.data;

  return data === undefined ? null : data;
});

export const saveProfile = createAsyncThunk('profilesApp/profile/saveProfile', async (profiledata) => {
  console.error("SaveProfileData >>>");
  console.error({profiledata});
  return false;
  const response = await axios.put('/api/ecommerce/orders', order);
  const data = await response.data;

  return data;
});

const profileSlice = createSlice({
  name: 'profilesApp/profile',
  initialState: null,
  reducers: {
    resetProfile: () => null,
  },
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => action.payload,
    [saveProfile.fulfilled]: (state, action) => action.payload,
  },
});

export const { resetProfile } = profileSlice.actions;

export const selectOrder = ({ profilesApp }) => profilesApp.profile;

export default profileSlice.reducer;
