import i18next from 'i18next';

import DataFieldPage from './DataField';
import AddDataFieldPage from './AddDataField';
import EditDataFieldPage from './EditDataField';
import authRoles from '../../auth/authRoles';


const DataFieldConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: 'admin/data-field',
      element: <DataFieldPage />,
    },
    {
      path: 'admin/add/data-field',
      element: <AddDataFieldPage />,
    },
    {
      path: 'admin/edit/data-field/:id',
      element: <EditDataFieldPage />,
    }
  ],
};

export default DataFieldConfig;