import { createAsyncThunk,  createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getUsersAction = createAsyncThunk('reportApp/useractions/get', async (state) => {
  const response = await axios.post('/api/report/action/userlist',{...state});
  const data = await response.data;
  return data;
});


const usersActionSlice = createSlice({
  name: 'reportApp/useractions',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getUsersAction.fulfilled]: (state, action) => action.payload,
  },
});


export const selectusersaction = ({ reportApp }) =>  reportApp.useractions;

export default usersActionSlice.reducer;