import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import history from '@history';

export const getDataField = createAsyncThunk(
  'profilesApp/datafields/getDataField',
  async (params,{ getDataField }) => {
      
      const response = await axios.get(`/api/datafield/list/frontend`);
      const data = await response.data;
      return data;
    
    }
);


export const selectDataField = ({ profilesApp }) => profilesApp.datafields;

const datafieldSlice = createSlice({
  name: 'profilesApp/datafields',
  initialState: null,
  reducers: {},
  extraReducers: {
    [getDataField.pending]: (state, action) => null,
    [getDataField.fulfilled]: (state, action) => action.payload
  },
});


export default datafieldSlice.reducer;